import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'airbnb-js-shims'

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'

import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import './assets/css/tailwind.css'
import '../static/icons/style.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'simplebar/dist/simplebar.min.css'

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

Vue.use(VueVideoPlayer)

Vue.use(Vuetify, {
    theme: {
        primary: colors.teal.darken2,
        primaryComplementary: '#fff',
        secondary: '#333',
        accent: colors.teal.accent3,
        accentComplementary: '#fff',
        baseColor: '#f5f5f5',
        borderColor: '#e5e5e5',
        blackColor: '#333', // mistake
        fadeBlack: '#333', // real
        error: colors.red,
        completedGreen: '#4caf50'
    },
    iconfont: 'mdi',
    options: {
        customProperties: true
    }
})

import Snotify, {SnotifyPosition} from 'vue-snotify'
import 'vue-snotify/styles/material.css'

// const options = {
//     toast: {
//         position: SnotifyPosition.rightTop,
//         timeout: 1500,
//         showProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true
//     }
// }

Vue.use(Snotify)

// import edaptioDataCollector from '@/plugins/edaptioDataCollector';

// Vue.use(edaptioDataCollector, {
//     dryRun: false,
//     debugMode: process.env.NODE_ENV === 'development',
//     dataServiceToken: process.env.DATA_COLLECTOR_SERVICE_TOKEN,
//     dataServiceEndpointUrl: process.env.DATA_COLLECTOR_SERVICE_URL,
//     timeSpentInAppMaxDataEntities: 3,
//     timeSpentInAppDataCollectionPeriod: 60000,
//     timeSpentInGroupMaxDataEntities: 3,
//     timeSpentInGroupDataCollectionPeriod: 60000,
// })

/**
 * Froala editor
 */
//import VueFroala from 'vue-froala-wysiwyg'
//import 'froala-editor/css/froala_editor.pkgd.min.css'

//import '@wiris/mathtype-froala3/wiris.js'

//Vue.use(VueFroala)

// only track bug on staging or production
const isProduction = (process.env.NODE_ENV === 'production')
const isStaging = (process.env.NODE_ENV === 'staging')
if (!isProduction) {
    Vue.config.performance = true
}

import App from './App'
import auth from './auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuthenticate from 'vue-authenticate'
import moment from 'moment'
import router from './router/mainRouter'
import store from './store/mainStore'
import i18n from './i18n/i18nMain'
import edaptioDialogue from '@/plugins/edaptioDialogue';

// Response interceptor - logout user if invalid token
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        // do redirect and logout if on specific routes
        const route = (router && router.currentRoute && router.currentRoute.path) ? router.currentRoute.path : null
        const invalid_route = (route && (route.includes('/join/') || route.includes('/signup')))
        // Do something with response error
        if (!invalid_route && error.response && error.response.status === 401) {
            auth.logout()
            router.replace('/login')
        }
        return Promise.reject(error.response)
    }
)
// set base API URL
axios.defaults.baseURL = process.env.BASE_API_URL

// set auth header on start up if token is present
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('id_token')


Vue.use(VueAxios, axios)
Vue.use(VueAuthenticate, {
    baseUrl: process.env.BASE_URL,
    bindResponseInterceptor: function () {
        this.$http.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                // Do something with response error
                if (error.response && error.response.status === 401) {
                    auth.logout()
                    router.replace('/login')
                }
                return Promise.reject(error.response)
            }
        )
    },
    bindRequestInterceptor: function () {
        this.$http.interceptors.request.use((config) => {
            return config
        })
    },
    providers: {
        facebook: {
            name: 'facebook',
            clientId: process.env.FACEBOOK_APP_ID,
            redirectUri: `${process.env.BASE_URL}/login`,
            responseType: 'token',
            authorizationEndpoint: 'https://www.facebook.com/v3.0/dialog/oauth',
            // authorizationEndpoint: 'https://www.facebook.com/v2.5/dialog/oauth',
            // redirectUri: getRedirectUri('/'),
            // requiredUrlParams: ['display', 'scope'],
            scope: ['email'],
            // scopeDelimiter: ',',
            // display: 'popup',
            // oauthType: '2.0',
            popupOptions: {width: 580, height: 400}
        },
        google: {
            name: 'google',
            clientId: process.env.GOOGLE_CLIENT_ID,
            // responseType: "token",
            redirectUri: `${process.env.BASE_URL}/login`,
            responseType: 'token',
            // authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
            // requiredUrlParams: ['scope'],
            // optionalUrlParams: ['display'],
            scope: ['openid', 'email', 'profile'],
            // scopePrefix: 'openid',
            // scopeDelimiter: ' ',
            // display: 'popup',
            // oauthType: '2.0',
            popupOptions: {width: 452, height: 633}
        },
        oauth2: {
            name: 'microsoft',
            url: `${process.env.BASE_API_URL}/auth/microsoft/login`,
            clientId: process.env.MICROSOFT_CLIENT_ID,
            redirectUri: `${process.env.BASE_URL}/login`,
            authorizationEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
            defaultUrlParams: [
                'response_type',
                'client_id',
                'redirect_uri',
                'scope'
            ],
            requiredUrlParams: null,
            optionalUrlParams: null,
            scope: 'https://graph.microsoft.com/user.read',
            scopePrefix: null,
            scopeDelimiter: null,
            state: null,
            oauthType: '2.0',
            popupOptions: null,
            responseType: 'code',
            responseParams: {
                code: 'code',
                clientId: 'clientId',
                redirectUri: 'redirectUri',
            },
        },
    }
})

Vue.config.productionTip = false

// global navigation guard for authentication
router.beforeEach(async (to, from, next) => {
    // clear all tooltips
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!auth.checkAuth()) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    }
    if (to.matched.some(record => record.meta.requiresInstitutionSetting)) {
        let settingName = to.meta.requiresInstitutionSetting.name
        let settingValue = to.meta.requiresInstitutionSetting.value
        let pathIfFail = to.meta.requiresInstitutionSetting.pathIfFail

        if (settingName && settingValue) {
            await store.dispatch(
                'administrationStore/getInstitutionSettings',
                {
                    forceRefresh: true
                })

            let found = false
            for (let setting of store.state.administrationStore.institutionSettings.settings) {
                if (setting.name === settingName && setting.value === settingValue) {
                    found = true
                    break
                }
            }

            found ? next() : next({path: pathIfFail ? pathIfFail : '/403'})
        }
    }
    next() // make sure to always call next()!
})

// router.beforeEach(async (to, from, next) => {
    // const user = store.state.userStore && store.state.userStore.user ? store.state.userStore.user : null;
    // const toRouteGroupId = to.params && to.params.group_id ? to.params.group_id : null;
    // const fromRouteGroupId = from.params && from.params.group_id ? from.params.group_id : null;

    // if (user && user.user_id && user.user_id !== '0') {
        // if (fromRouteGroupId && fromRouteGroupId !== toRouteGroupId) {
        //     Vue.prototype.$dataCollector.stopTimeSpentInGroupDataCollection();
        // }

        // if (toRouteGroupId && toRouteGroupId !== fromRouteGroupId) {
        //     Vue.prototype.$dataCollector.startTimeSpentInGroupDataCollection({
        //         groupId: toRouteGroupId,
        //         userId: +user.user_id,
        //         firstName: user.firstname || '',
        //         lastName: user.lastname || '',
        //     });
        // }
    // }

//     next();
// })

// always update userpilot after
// router.afterEach((to, from) => {
//
//     try {
//         if (userpilot) {
//             Vue.nextTick(() => {
//                 userpilot.reload()
//             })
//         }
//     } catch (e) {
//     }
//
// })

Vue.filter("formatDateSubmission", function(value) {
    if (typeof value !== "string" || /^[0-9]+$/.test(value)) {
        value = +value * 1000;
    }
    if (value) {
        const dayOfWeek = moment
            .utc(value)
            .local()
            .format("dddd")

        const time = moment
            .utc(value)
            .local()
            .format("HH:mm")

        return `${this.$t(`weekdays.${dayOfWeek}`)} ${moment
            .utc(value)
            .local()
            .format("DD/MM")} ${time}`
    }
});

Vue.filter('formatDate', function (value) {
    if (typeof value !== 'string' || /^[0-9]+$/.test(value)) {
        value = (+value) * 1000
    }

    if (value) {
        if (store.state.userStore.user_settings.language === 'en') {
            return moment.utc(value).local().format('YYYY-MM-DD')
        } else {
            return moment.utc(value).local().format('DD/MM/YYYY')
        }
    }
})

Vue.filter('formatTime', function (value) {
    if (typeof value !== 'string' || /^[0-9]+$/.test(value)) {
        value = (+value) * 1000
    }

    if (value) {
        if (store.state.userStore.user_settings.language === 'en') {
            return moment.utc(value).local().format('HH:mm')
        } else {
            return moment.utc(value).local().format('HH:mm')
        }
    }
})

Vue.filter('formatDateAndTime', function (value, language) {
    if (!value) return "";
    if (
        (typeof value !== "string" || /^[0-9]+$/.test(value)) &&
        value.toString().length < 13
    ) {
        value = +value * 1000;
    }

    if (value) {
        if (language) {
            if (language === "en") {
                return moment
                    .utc(value)
                    .local()
                    .format("YYYY-MM-DD HH:mm");
            }
            if (language === "da") {
                return moment
                    .utc(value)
                    .local()
                    .format("DD/MM/YYYY HH:mm");
            }
        }
        if (store.state.userStore.user_settings.language === "da") {
            return moment
                .utc(value)
                .local()
                .format("DD/MM/YYYY HH:mm");
        }
        return moment
            .utc(value)
            .local()
            .format("YYYY-MM-DD HH:mm");
    }
})

Vue.filter('toHHMMSS', function (value) {
    let sec_num = parseInt(value, 10) // don't forget the second param
    let hours = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    let seconds = sec_num - (hours * 3600) - (minutes * 60)

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds
})


// ALL DEFAULT VALUES CAN BE ACCESSED by components
Vue.mixin({
    data() {
        return {
            defaultErrorMessages: {
                invalidRange: ({ min, max }) =>
                    `${this.$t("Must be between")} ${min} - ${max}`,
                invalidType: ({ type = "" }) => `${this.$t("Invalid")} ${type}`,
                required: () => this.$t("Required"),
                tooFewType: ({ min, ofType = "" }) =>
                    `${this.$t("Requires at least")} ${min} ${ofType}`
            }
        };
    },

    computed: {
        defaultValuesCurrentEnvironmentBaseURL() {
            const production = this.defaultValuesIsProduction;
            const staging = this.defaultValuesIsStaging;
            return production
                ? "https://app.edaptio.com"
                : staging
                ? "https://stg.edaptio.com"
                : "http://localhost:8080";
        },
        defaultValuesIsProduction() {
            return process.env.NODE_ENV === "production";
        },
        defaultValuesIsStaging() {
            return process.env.NODE_ENV === "staging";
        },
        defaultValuesLibraryGroupId() {
            return 3;
        },
        defaultValuesProfilePictureURL() {
            return "https://images.edaptio.com/user-placeholder-pic.png";
        },
        isExtraLargeDevice() {
            return store.state.generalStore.device === "laptop" || false;
        },
        isLargeDevice() {
            return store.state.generalStore.device === "laptop" || false;
        },
        isMediumDevice() {
            return store.state.generalStore.device === "tablet" || false;
        },
        isSmallDevice() {
            return store.state.generalStore.device === "mobile" || false;
        },
        getCDNPrefix() {
            return "https://cdn.filestackcontent.com";
        },
        getUserLanguage() {
            if (
                store.state.userStore &&
                store.state.userStore.user_settings &&
                store.state.userStore.user_settings.language
            ) {
                return store.state.userStore.user_settings.language;
            }
            if (!this.$i18n) return "en";
            return this.$i18n.locale || "en";
        }
    },
    methods: {
        /**
         * Function for fixing the deeply annoying problem of array references in JS
         * @param object_slash_array any object or array
         * @returns {any}
         */
        deepCopy(object_slash_array) {
            return JSON.parse(JSON.stringify(object_slash_array))
        },

        formatDateSubmission(value) {
            if (typeof value !== "string" || /^[0-9]+$/.test(value)) {
                value = +value * 1000;
            }
            if (value) {
                const dayOfWeek = moment
                    .utc(value)
                    .local()
                .format("dddd")
              const time = moment
                  .utc(value)
                  .local()
                  .format("HH:mm")

                return `${this.$t(`weekdays.${dayOfWeek}`)} ${moment
                    .utc(value)
                    .local()
                    .format("DD/MM")} ${time}`
          }
          return ''
        },

        dateAddDays(date, days) {
            if (!date) return ""
            date = date * 1000
            const convertedDate = moment
                .utc(date)
                .local()
                .format("YYYY-MM-DD")
            if (store.state.userStore.user_settings.language === "da") {
                return moment(convertedDate)
                    .add("days", days)
                    .format("DD/MM/YYYY")
            }
            return moment(convertedDate)
                .add("days", days)
                .format("YYYY-MM-DD")
        },

        getUserDefaultPicture(userId) {
            if (!userId) return 0;
            const bgColors = [
                "#6E44FF",
                "#35A7FF",
                "#FF5964",
                "#FF9466",
                "#1E3888",
                "#00CC66",
                "#020402",
                "#108D82",
                "#F4D507",
                "#868282",
                "#62DBB5"
            ]
            const sum = userId
                .split("")
                .map(Number)
                .reduce((a, b) => a + b)
            const reminder = (sum / bgColors.length).toFixed(1)
            return bgColors[Math.ceil((reminder % 1) * 10)] || "#62DBB5"
        },

        transformMessageDate(value) {
            if (typeof value !== "string" || /^[0-9]+$/.test(value)) {
                value = +value * 1000;
            }
            if (value) {
                let result = "";
                const formatedDateTime = moment
                    .utc(value)
                    .local()
                    .format("YYYY-MM-DD HH:mm")
                const formatedDate = moment
                    .utc(value)
                    .local()
                    .format("YYYY-MM-DD")
                const daysFromNow = moment().diff(formatedDate, "days")
                let dateText = ""
                if (daysFromNow === 0) {
                    const secondsFromNow = moment().diff(
                        formatedDateTime,
                        "second"
                    );
                    if (secondsFromNow < 200) return this.$t("Now");
                    dateText = this.$t("Today")
                }
                if (daysFromNow === 1) {
                    dateText = this.$t("Yesterday")
                }
                if (store.state.userStore.user_settings.language === "da") {
                    result = moment
                        .utc(value)
                        .local()
                        .format("DD/MM/YYYY HH:mm")
                } else {
                    result = formatedDateTime
                }
                if (dateText) {
                    const hours = moment(formatedDateTime).format("HH:mm");
                    return `${dateText} ${hours}`
                }
                return result
            }
            return ""
        },
        formatDateAndTime(value, language) {
            if (!value) return ""
            if (
                (typeof value !== "string" || /^[0-9]+$/.test(value)) &&
                value.toString().length < 13
            ) {
                value = +value * 1000
            }

            if (value) {
                if (language) {
                    if (language === "en") {
                        return moment
                            .utc(value)
                            .local()
                            .format("YYYY-MM-DD HH:mm");
                    }
                    if (language === "da") {
                        return moment
                            .utc(value)
                            .local()
                            .format("DD/MM/YYYY HH:mm");
                    }
                }
                if (store.state.userStore.user_settings.language === "da") {
                    return moment
                        .utc(value)
                        .local()
                        .format("DD/MM/YYYY HH:mm");
                }
                return moment
                    .utc(value)
                    .local()
                    .format("YYYY-MM-DD HH:mm");
          }
          return ''
        },
        formatToDate(value, language) {
            if (!value) return ""
            if (
                (typeof value !== "string" || /^[0-9]+$/.test(value)) &&
                value.toString().length < 13
            ) {
                value = +value * 1000
            }

            if (value) {
                if (
                    store.state.userStore.user_settings.language === "da" ||
                    language === "da"
                ) {
                    return moment
                        .utc(value)
                        .local()
                        .format("DD/MM/YYYY")
                }
                return moment
                    .utc(value)
                    .local()
                    .format("YYYY-MM-DD")
          }
          return ''
        },

        formatUnixTimestampToLL(value) {
            if (!value) return ""
            if (
                (typeof value !== "string" || /^[0-9]+$/.test(value)) &&
                value.toString().length < 13
            ) {
                value = +value * 1000
            }

            if (value) {
                return moment
                    .utc(value)
                    .local()
                    .format("ll");
          }
          return ''
        },

        formatDateAndTimeForDatepicker(value) {
            if (!value) return 0
            value = +value * 1000
            return moment
                .utc(value)
                .local()
                .format("YYYY-MM-DD")
        },
        formatDateAndTimeForBackend(value) {
            if (!value) return 0
            return moment
                .utc(value)
                .local()
                .format("YYYY-MM-DD HH:mm")
        },

        getTimestampTime(value) {
            if (!value) return 0
            value = +value * 1000
            return moment
                .utc(value)
                .local()
                .format("HH:mm:ss")
        },

        defaultFunctionsCapitalizeFirstLetter(string) {
            if (!string || !string.length) {
                return string
            }
            return string.charAt(0).toUpperCase() + string.slice(1)
        },

        defaultFunctionsCopyToClipboard(str) {
            const el = document.createElement("textarea");
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        },

        defaultFunctionsGenerateRandomString() {
            return (Math.random() + 1).toString(36).substring(2, 14);
        },

        defaultFunctionsNewlineToBR(str) {
            return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
        },

        defaultFunctionsStripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },

        defaultFunctionsFormatHtml(html) {
            // html = html.replace(new RegExp(/<script[^>]*>([\\S\\s]*?)<\/script>/img, 'g'), '');
            // remove script tags
            html = html.replace(
                "<script(?:(?!\\/\\/)(?!\\/\\*)[^'\"]|\"(?:\\\\.|[^\"\\\\])*\"|'(?:\\\\.|[^'\\\\])*'|\\/\\/.*(?:\\n)|\\/\\*(?:(?:.|\\s))*?\\*\\/)*?<\\/script>",
                ""
            );
            // remove inline style
            // html = html.replace(new RegExp(/style="[^"]*"/, 'g'), '');

            return html;
        },

        defaultFunctionsRemoveNewlines(string) {
            // newlines
            string = string.replace(/\r?\n|\r/g, "");
            // br elements
            string = string.replace(/&lt;br&gt;/g, "");
            // &nbsp;
            string = string.replace(/&nbsp;/gi, "");

            return string;
        },

        defaultFunctionsStripYoutubeIdFromURL(url) {
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            const match = url.match(regExp);
            if (match && match[2].length == 11) {
                return match[2];
            } else {
                return "";
            }
        },

        defaultFunctionsTestIsUrl(str) {
            const pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                    "(\\#[-a-z\\d_]*)?$",
                "i"
            ); // fragment locator
            return pattern.test(str);
        },

        defaultFunctionsTestIsEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        // function for getting the difference between two arrays
        getArrayDifference(full_array, subset) {
            const all = new Set(full_array);
            const used = new Set(subset);
            const setDifference = (a, b) =>
                new Set([...a].filter(x => !b.has(x)));
            return [...setDifference(all, used)];
        },

        generateLinkFromURL(inputText) {
            let replacedText, replacePattern1, replacePattern2, replacePattern3;

            //URLs starting with http://, https://, or ftp://
            replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            replacedText = inputText.replace(
                replacePattern1,
                '<a href="$1" target="_blank">$1</a>'
            );

            //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
            replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            replacedText = replacedText.replace(
                replacePattern2,
                '$1<a href="http://$2" target="_blank">$2</a>'
            );

            //Change email addresses to mailto:: links.
            replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
            replacedText = replacedText.replace(
                replacePattern3,
                '<a href="mailto:$1">$1</a>'
            );

            return replacedText;
        },

        spawnSuccessToast(message, time_to_live=1500, title=this.$t("Success")) {
            message = message ? message : "";
            time_to_live = time_to_live ? time_to_live : 1500;
            this.$snotify.success(message, title, {
                position: SnotifyPosition.leftBottom,
                timeout: 1500,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                color: "completedGreen"
            });
        },

        spawnErrorToast(message, time_to_live) {
            message = message ? message : "";
            time_to_live = time_to_live ? time_to_live : 1500;
            this.$snotify.error(message, this.$t("Error"), {
                position: SnotifyPosition.leftBottom,
                timeout: 1500,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true
            });
        },

        /**
         * @param value
         * @param {Object=} options
         * @param {string=} options.returnFormat
         * @param {boolean=} options.returnTimestamp
         * @param {boolean=} options.returnTimestampInSeconds
         * @return {string|number}
         */
        convertDateTimeToUtc(value, options) {
            options = options || {};

            const returnFormat = options.returnFormat || "YYYY-MM-DD HH:mm:ss";
            const returnTimestamp = options.returnTimestamp || false;
            const returnTimestampInSeconds =
                options.returnTimestampInSeconds || false;

            const dateTime =
                value instanceof moment
                    ? moment(value.toDate().getTime())
                    : moment(value);

            if (returnTimestamp) {
                return returnTimestampInSeconds
                    ? Math.floor(dateTime.toDate().getTime() / 1000)
                    : dateTime.toDate().getTime();
            }

            return dateTime.utc().format(returnFormat);
        },

        trackEvent({ name, options = {}, properties = {}, user = {} }) {
            if (!window.EncTracking) return 0;
            try {
                window.EncTracking.track({
                    name,
                    properties: {
                        ...properties,
                        date: new Date()
                    },
                    user: {
                        email: store.state.userStore.user.email || "",
                        firstName: store.state.userStore.user.firstname || "",
                        lastName: store.state.userStore.user.lastname || "",
                        institutionType: store.state.userStore
                            .default_institution
                            ? store.state.userStore.default_institution.client
                                  .client_type
                            : "",
                        ...user
                    },
                    ...options
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
});

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render(h) {
        return h('App')
    },
    components: {App}
})
