/*
  LANGUAGE FILE FOR ENGLISH LANG
 */

export default {
    "€0.60/month for each additional learner over the prepaid amount":
        "€0.60/month for each additional learner over the prepaid amount",
    "€1.20/month for each additional learner over the prepaid amount":
        "€1.20/month for each additional learner over the prepaid amount",
    "2GB storage limit": "2GB storage limit",
    "10GB storage limit": "10GB storage limit",
    "30GB storage limit": "30GB storage limit",
    "3-digit code placed on the back side of the card, in the signature area":
        "3-digit code placed on the back side of the card, in the signature area",
    "A-Niveau": "A-Niveau",
    Abandon: "Abandon",
    "Abandon copying to this group": "Abandon copying to this class",
    "Accept terms": "Accept terms",
    "Account already linked": "Account already linked",
    "Account linked": "Account linked",
    "Access denied!": "Access denied!",
    "Account succesfully linked with": "Account succesfully linked with",
    "Account type": "Account type",
    Actions: "Actions",
    Active: "Active",
    "Active learners": "Active learners",
    "Active learners above the plan in this month":
        "Active learners above the plan in this month",
    "Active learners in this month": "Active learners in this month",
    "Active learners on plan": "Active learners on plan",
    Activity: "Activity",
    Activities: "Activities",
    "Activities, quizzes & assignments": "Activities, quizzes & assignments",
    "Adaptive settings": "Adaptive settings",
    "Adaptive test": "Adaptive test",
    "Adaptive tests": "Adaptive tests",
    Add: "Add",
    "Additional group": "Additional class",
    "Add a document": "Add a document",
    "Add a file": "Add a file",
    "Add a new learner or co-teacher": "Add a new learner or co-teacher",
    "Add activities": "Add activities",
    "Add additional group": "Add additional class",
    "Add answer": "Add answer",
    "Add assignment": "Add assignment",
    "Add attachment": "Add attachment",
    "Add audio": "Add audio",
    "Add board": "Add pinboard",
    "Add board item": "Add pinboard item",
    "Add category": "Add category",
    "Add collection": "Add collection",
    "Add conversation name": "Add conversation name",
    "Add course": "Add course",
    "Add dashboard widget": "Add dashboard widget",
    "Add description": "Add description",
    "Add discussion topic": "Add debate topic",
    "Add event": "Add event",
    "Add existing activities": "Add existing activities",
    "Add existing material": "Add existing lesson",
    "Add expression": "Add expression",
    "Add external link": "Add external link",
    "Add extra item": "Add extra item",
    "Add feedback": "Add feedback",
    "Add file": "Add file",
    "Add files/folders": "Add files/folders",
    "Add first learning element to this course":
        "Add the first learning activity to this course",
    "Add flashcard": "Add flashcard",
    "Add folder": "Add folder",
    "Add geogebra": "Add Geogebra",
    "Add image": "Add image",
    "Add information": "Add information",
    "Add item": "Add item",
    "Add label": "Add label",
    "Add labels to the image": "Add labels to the image",
    "Add learning activities": "Add learning activities",
    "Add learning activity": "Add learning activity",
    "Add lesson": "Add lesson",
    "Add lessons to your course": "Add lessons to your course",
    "Add image label": "Add image label",
    "Add match": "Add match",
    "Add match set": "Add match set",
    "Add material": "Add lesson",
    "Add new course": "Add new course",
    "Add new dependency": "Add new dependency",
    "Add new flashcard": "Add new flashcard",
    "Add new institution": "Add new institution",
    "Add new item": "Add new item",
    "Add new learning element": "Add new learning activity",
    "Add new match option": "Add new match option",
    "Add new material": "Add new lesson",
    "Add new question": "Add new question",
    "Add new tag": "Add new tag",
    "Add news": "Add news",
    "Add numeric variable": "Add numeric variable",
    "Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.":
        "Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.",
    "Add question": "Add question",
    "Add questions": "Add questions",
    "Add question from questionbank": "Add question from questionbank",
    "Add questionnaire": "Add questionnaire",
    "Add quiz": "Add quiz",
    "Add one learning element at a time. Save changes in order to continue creating.":
        "Add one learning activity at a time. Save changes in order to continue creating.",
    "Add text": "Add text",
    "Add text label": "Add text label",
    "Add timeline": "Add timeline",
    "Add to existing activity": "Add to existing activity",
    "Add to word list": "Add to word list",
    "Add video": "Add video",
    "Add Vimeo video": "Add Vimeo video",
    "Add youtube video": "Add youtube video",
    "Add your first document that will be shared with your class":
        "Add your first document that will be shared with your class",
    Added: "Added",
    "Add groups": "Add classes",
    "Add new answer possibility": "Add new answer",
    "Add new expression": "Add new expression",
    "Add new element": "Add new activity",
    "Add new nummeric variable": "Add new nummeric variable",
    "Add member": "Add member",
    "Add members": "Add members",
    "Add members manually to the class below":
        "Add members manually to the class below",
    "Add members to this class": "Add members to this class",
    "Add teachers to your plan": "Add teachers to your plan",
    "Add participants": "Add participants",
    "Add submission example": "Add submission example",
    "Add title": "Add title",
    "Add vacation/holiday": "Add vacation/holiday",
    "Add word": "Add word",
    "Add words to the word list. Any number of words from the list can be chosen at random.":
        "Add words to the word list. Any number of words from the list can be chosen at random.",
    "Adjust your settings and start a call":
        "Adjust your settings and start the call",
    Admin: "Admin",
    Admins: "Admins",
    Administration: "Administration",
    "Advanced integrations": "Advanced integrations",
    "Advanced settings": "Advanced settings",
    Afrikaans: "Afrikaans",
    Albanian: "Albanian",
    All: "All",
    "All changes were saved": "All changes were saved",
    "All classes, courses and content will be transferred, and nothing will be lost.":
        "All classes, courses and content will be transferred, and nothing will be lost.",
    "All correct answers must be chosen": "All correct answers must be chosen",
    "All correct answers must be selected":
        "All correct answers must be selected",
    "All communication suspended.": "All communication suspended.",
    "All file types": "All file types",
    "All topics": "All topics",
    "All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold":
        "All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold",
    "Allow counsellors": "Allow counsellors",
    "Allow direct user creation in the group":
        "Allow direct learner creation in the group",
    "Allow groups": "Allow classes",
    "Allow code join": "Allow code join",
    "Allow late submission": "Allow late submission",
    "Allow late turnin": "Allow late submission",
    "Allow groups code join": "Allow groups code join",
    "Allow questions on material elements":
        "Allow questions on learning activities",
    "All participants from your class can access this call. If you want to invite others, send them a link.":
        "All participants from your class can access this call. If you want to invite others, send them the call link.",
    "Allow peers": "Allow peers",
    "Allow students to see results": "Allow learners to see results",
    "Allowed file types": "Allowed file types",
    "Already a user?": "Already a user?",
    "Already have an account?": "Already have an account?",
    Amount: "Amount",
    "An error occurred": "An error occurred",
    "An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.":
        "An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.",
    "Ancient history": "Ancient history",
    Answer: "Answer",
    "Answer's feedback": "Answer's feedback",
    "Answer date": "Answer date",
    "Answer {number} more": "Answer {number} more",
    "Answer evaluation criteria": "Answer evaluation criteria",
    "Answer possibilities": "Answers",
    "Answer possibility": "Answer",
    "Answer the question to continue the video":
        "Answer the question to continue the video",
    Answers: "Answers",
    "Answers must have a unique match option assigned":
        "Answers must have a unique match option assigned",
    "Answers not in category": "Answers not in category",
    "Answers will be shown after quiz is completed":
        "Answers will be shown after quiz is completed",
    "Appears on": "Appears on",
    Apps: "Apps",
    Arabic: "Arabic",
    Archive: "Archive",
    "Archive class": "Archive class",
    "Archive class for all members": "Archive class for all members",
    "Archive for all": "Archive for all",
    "Archive for you": "Archive for you",
    Archived: "Archived",
    "Archived groups": "Archived classes",
    "Are you a student or a teacher?": "Are you a learner or a teacher?",
    "Are you sure?": "Are you sure?",
    "Are you sure you have checked the list thoroughly? Remember you can search it too!":
        "Are you sure you have checked the list thoroughly? Remember you can search it too!",
    "Are you sure you want to archive this conversation?":
        "Are you sure you want to archive this conversation?",
    "Are you sure you want to archive this group for all members?":
        "Are you sure you want to archive this class for all members?",
    "Are you sure you want to delete this?":
        "Are you sure you want to delete this?",
    "Are you sure you want to delete this assignment?":
        "Are you sure you want to delete this assignment?",
    "Are you sure you want to delete this collection?":
        "Are you sure you want to delete this collection?",
    "Are you sure you want to delete this feedback?":
        "Are you sure you want to delete this feedback?",
    "Are you sure you want to delete this file?":
        "Are you sure you want to delete this file?",
    "Are you sure you want to delete this folder?":
        "Are you sure you want to delete this folder? This will also remove any subfolder and file in the folder.",
    "Are you sure you want to delete this Geogebra element?":
        "Are you sure you want to delete this Geogebra activity?",
    "Are you sure you want to delete this group?":
        "Are you sure you want to delete this class?",
    "Are you sure you want to delete this group information?":
        "Are you sure you want to delete this class information?",
    "Are you sure you want to delete this post?":
        "Are you sure you want to delete this post?",
    "Are you sure you want to delete this question?":
        "Are you sure you want to delete this question?",
    "Are you sure you want to delete this quiz?":
        "Are you sure you want to delete this quiz?",
    "Are you sure you want to delete this user?":
        "Are you sure you want to delete this user?",
    "Are you sure you want to delete this widget?":
        "Are you sure you want to delete this widget?",
    "Are you sure you want to discard this learning activity?":
        "Are you sure you want to discard this learning activity?",
    "Are you sure you want to leave the meeting?":
        "Are you sure you want to leave the meeting?",
    "Are you sure you want to mark student as a self-study? This will move all assignments years into the future.":
        "Are you sure you want to mark learner as a self-study? This will move all assignments years into the future.",
    "Are you sure you want to open learner view without saving the changes?":
        "Are you sure you want to open learner view without saving the changes?",
    "Are you sure you want to recalculate assignment plan?":
        "Are you sure you want to recalculate assignment plan?",
    "Are you sure you want to remove from your plan?":
        "Are you sure you want to remove from {insertion} your plan?",
    "Are you sure you want to remove this answer?":
        "Are you sure you want to remove this answer?",
    "Are you sure you want to remove this course?":
        "Are you sure you want to remove this course?",
    "Are you sure you want to remove this label?":
        "Are you sure you want to remove this label?",
    "Are you sure you want to remove this learning element?":
        "Are you sure you want to remove this learning activity?",
    "Are you sure you want to remove this lesson?":
        "Are you sure you want to remove this lesson?",
    "Are you sure you want to remove this member?":
        "Are you sure you want to remove this member?",
    "Are you sure you want to remove this match set?":
        "Are you sure you want to remove this match set?",
    "Are you sure you want to remove this material?":
        "Are you sure you want to remove this lesson?",
    "Are you sure you want to remove this question?":
        "Are you sure you want to remove this question?",
    "Are you sure you want to remove this subject?":
        "Are you sure you want to remove this subject?",
    "Are you sure you want to remove this topic?":
        "Are you sure you want to remove this topic?",
    "Are you sure you want to remove this variable?":
        "Are you sure you want to remove this variable?",
    "Are you sure you want to remove this user?":
        "Are you sure you want to remove this user?",
    "Are you sure you want to remove this widget?":
        "Are you sure you want to remove this widget?",
    "Are you sure you want to remove this word?":
        "Are you sure you want to remove this word?",
    "Are you sure you want to start a video call?":
        "Are you sure you want to start a video call?",
    "Are you sure you want to start a video call with this user?":
        "Are you sure you want to start a video call with this user?",
    "Are you sure you want to submit this questionnaire?":
        "Are you sure you want to submit this questionnaire?",
    "Are you sure you want to turn in this quiz?":
        "Are you sure you want to turn in this quiz?",
    "Are you sure you want to unarchive this group?":
        "Are you sure you want to unarchive this class?",
    "Are you sure you want to unlink your account from":
        "Are you sure you want to unlink your account from",
    "Are you sure you want to unsubscribe?":
        "Are you sure you want to unsubscribe?",
    "Are you unsure? Click the button below to read a guide on this":
        "Are you unsure? Click the button below to read a guide on this",
    Armenian: "Armenian",
    Arrows: "Arrows",
    "As a teacher, you are automatically always in training mode, and we will not store your answers, but you can test the quiz.":
        "As a teacher, you are automatically always in training mode, and we will not store your answers, but you can test the quiz.",
    "Ask to join": "Ask to join",
    "Ask your students to go to": "Ask your learners to go to",
    Assessments: "Assessments",
    Assign: "Assign",
    "Assign category": "Assign category",
    "Assign learners to counsellors": "Assign learners to counsellors",
    "Assign student": "Assign learner",
    "Assign student to": "Assign learner to",
    "Assign task to Olly": "Assign task to Olly",
    Assignment: "Assignment",
    assignment_objects: "Assignment",
    assignments: "Assignments",
    "Assignments behind": "Assignments behind",
    "Assignments completion percentage": "Assignments completion percentage",
    "Assignment answer": "Assignment answer",
    "Assignment created": "Submission created",
    "Assignment handin timeline": "Assignment submission timeline",
    "Assignment handins/feedback statistics":
        "Assignment submission/feedback statistics",
    "Assignment plans": "Submission plans",
    "Assignment rejected": "Assignment rejected",
    "Assignment removed": "Submission removed",
    "Assignment saved": "Submission saved",
    "Assignment statistics": "Assignment statistics",
    "Assignment text": "Assignment text",
    "Assignment title": "Assignment title",
    "Assignment turnins missing feedback":
        "Assignment submissions missing feedback",
    "Assignment turnin overview": "Assignment submissions overview",
    "At least one correct answer chosen": "At least one correct answer chosen",
    "Attach media": "Attach media",
    "Attach to feedback": "Attach to feedback",
    Attachments: "Attachments",
    Attachment: "Attachment",
    Attempt: "Attempt",
    "Average on teams": "Average for class",
    "Average quiz answer percentage for group":
        "Average quiz answer percentage for class",
    "Average user completion": "Average learner completion",
    Audio: "Audio",
    "Audio is on": "Audio is on",
    Australian: "Australian",
    "Awaiting feedback": "Awaiting feedback",
    "B-Niveau": "B-Niveau",
    Back: "Back",
    "Back to all news": "Back to all news",
    "Back to courses": "Back to courses",
    "Back to course overview": "Back to course overview",
    "Back to dashboard": "Back to dashboard",
    "Back to debate list": "Back to debate list",
    "Back to groups": "Back to classes",
    "Back to information list": "Back to information list",
    "Back to lessons overview": "Back to lessons overview",
    "Back to overview": "Back to overview",
    "Back to the dashboard": "Back to the dashboard",
    "Back to quizzes": "Back to quizzes",
    Bank: "Bank",
    Basic: "Basic",
    "Basic & advanced integrations": "Basic & advanced integrations",
    "Basic integrations": "Basic integrations",
    "Below are download links to the most popular browsers, in case you do not have them installed":
        "Below are download links to the most popular browsers, in case you do not have them installed",
    Billing: "Billing",
    "Billing history": "Billing history",
    Black: "Black",
    Blue: "Blue",
    Biology: "Biology",
    Board: "Pinboard",
    Bosnian: "Bosnian",
    "Brazilian Portuguese": "Brazilian Portuguese",
    "Build structured blocks of text to inform your students on exams procedures, tell them about yourself, or add a course description.":
        "Build structured blocks of text to inform your learners on exams procedures, tell them about yourself, or add a course description.",
    "Business economics": "Business economics",
    "By closing this modal you discard all learning activities prepared by Olly":
        "By closing this modal you discard all learning activities prepared by Olly",
    "By creating an account I accept Terms and Conditions and Edaptio's default Notification settings":
        "By creating an account I accept {termsLink} and Edaptio's default {notifLink}",
    "By deleting the last element you discard the whole learning activity":
        "By deleting the last {name} you discard the whole learning activity",
    "By signing up you accept our": "By signing up you accept our",
    "By unsubscribing you will continue to have plan access and be charged monthly for active learners above count until date. After this period you will only be able to keep 2GB of storage and 15 active learners.":
        "By unsubscribing you will continue to have {plan} access and be charged monthly for active learners above {learners} until {validUntil}. After this period you will only be able to keep 2GB of storage and 15 active learners.",
    "By unsubscribing you will be downgraded to Basic Plan at the end of this billing cycle. After this period you will only be able to keep 2GB of storage and 15 active learners.":
        "By unsubscribing you will be downgraded to Basic Plan at the end of this billing cycle. After this period you will only be able to keep 2GB of storage and 15 active learners.",
    "C-Niveau": "C-Niveau",
    Ca: "Ca",
    Calculate: "Calculate",
    "Calculate this expression": "Calculate this expression",
    Camera: "Camera",
    "Camera off": "Camera off",
    "Camera on": "Camera on",
    Cancel: "Cancel",
    "Cancel call": "Cancel call",
    "Card details": "Card details",
    "Card number": "Card number",
    "Cardholder name": "Cardholder name",
    "Case sensitive": "Case sensitive",
    Catalan: "Catalan",
    Categories: "Categories",
    Category: "Category",
    "Category items": "Category items",
    "changed the feedback for the assignment":
        "changed the feedback for the assignment",
    Changelog: "Changelog",
    "Change card": "Change card",
    "Change group": "Change class",
    "Change image": "Change image",
    "Change institution": "Change institution",
    "Change moodle relation": "Change moodle relation",
    "Change password": "Change password",
    "Change payment method": "Change payment method",
    "Change plan": "Change plan",
    "Change plan selection": "Change plan selection",
    "Change UMS relation": "Change UMS relation",
    characters: "characters",
    chat: "Chat",
    Chat: "Chat",
    Checkbox: "Checkbox",
    "Check schedule of a specific class or class member":
        "Check schedule of a specific class or class member",
    "Check your inbox!": "Check your inbox!",
    Chemestry: "Chemestry",
    "Change submission date for user": "Change submission date for learner",
    Chinese: "Chinese",
    "Co-creation": "Co-creation",
    "Connect Facebook": "Connect Facebook",
    "Connect Microsoft": "Connect Microsoft",
    "Connect Google": "Connect Google",
    "Connect Unilogin": "Connect Unilogin",
    "Connect your account": "Connect your account",
    Choose: "Choose",
    "Choose another element": "Choose another activity",
    "Choose another group": "Choose another class",
    "Choose assignment": "Choose assignment",
    "Choose assignment from group": "Choose assignment from class",
    "Choose between seeing quizzes the user has passed or completed.":
        "Choose between seeing quizzes the learner has passed or completed.",
    "Choose class": "Choose class",
    "Choose collection": "Choose collection",
    "Choose collection from group": "Choose collection from class",
    "Choose column representation": "Choose column representation",
    "Choose correct match option": "Choose correct match option",
    "Choose course": "Choose course",
    "Choose CSV file": "Choose CSV file",
    "Choose date": "Choose date",
    "Choose element": "Choose activity",
    "Choose element type": "Choose activity type",
    "Choose from filesharing": "Choose from filesharing",
    "Choose files": "Choose files",
    "Choose from existing materials": "Choose from existing lessons",
    "Choose gender": "Choose gender",
    "Choose Geogebra": "Choose Geogebra",
    "Choose Geogebra from group": "Choose Geogebra from class",
    "Choose grade": "Choose grade",
    "Choose group": "Choose group",
    "Choose institution": "Choose institution",
    "Choose material": "Choose lesson",
    "Choose members": "Choose members",
    "Choose language": "Choose language",
    "Choose learning element": "Choose learning activity",
    "Choose lesson": "Choose lesson",
    "Choose new file": "Choose new file",
    "Choose questionnaire": "Choose questionnaire",
    "Choose questionnaire from group": "Choose questionnaire from class",
    "Choose quiz": "Choose quiz",
    "Choose quiz from group": "Choose quiz from class",
    "Choose random words": "Choose random words",
    "Choose subgroup": "Choose subgroup",
    "Choose time": "Choose time",
    "Choose the default language of the group":
        "Choose the default language of the class",
    "Choose the strategy with which Olly should create activity":
        "Choose the strategy with which Olly should create {name}",
    "Choose type": "Choose type",
    "Choose user": "Choose user",
    "Choose weekday": "Choose weekday",
    "Choose what this group is teaching": "Choose what this class is teaching",
    Chosen: "Chosen",
    "Chosen file": "Chosen file",
    "Chosen group": "Chosen class",
    "Chosen monthly plan summary":
        "You are upgrading to {name} Plan. Plan automatically renews monthly until cancelled.",
    "Chosen yearly plan summary":
        "You are upgrading to 1 year of {name} access and save 25% by making a one time payment. Plan automatically renews yearly until canceled.",
    "Chosen user": "Chosen user",
    class: "class",
    Class: "Class",
    "Class admins": "Class admins",
    "Class code": "Class code",
    "class dashboard": "Class dashboard",
    "Class tracking & insights": "Class tracking & insights",
    Classes: "Classes",
    Classic: "Classic",
    "Class members": "Class members",
    "Class notebook link": "Class notebook link",
    "Class not found": "Class not found",
    "Class subject": "Class subject",
    Clear: "Clear",
    "Click enter to submit": "Click enter to submit",
    "Click here to add a new learning element or edit the selected element":
        "Click here to add a new learning activity or edit the selected element",
    "Click here to add existing material, create a new material or change the current material order":
        "Click here to add existing lesson, create a new lesson or change the current lesson order",
    "Click here to create a new assignment":
        "Click here to create a new assignment",
    "Click here to create a new conference post":
        "Click here to create a new conference post",
    "Click here to create a new conversation with a student or a group":
        "Click here to create a new conversation with a learner or a class",
    "Click here to create a new course or change the current course order":
        "Click here to create a new course or change the current course order",
    "Click here to create a new Geogebra element":
        "Click here to create a new Geogebra activity",
    "Click here to create a new material": "Click here to create a new lesson",
    "Click here to create a new news post":
        "Click here to create a new news post",
    "Click here to create a new quiz": "Click here to create a new quiz",
    "Click here to create a new question":
        "Click here to create a new question",
    "Click here to edit the group information text":
        "Click here to edit the class information text",
    "Click here to go to your class notebook":
        "Click here to go to your class notebook",
    "Click left/right arrows to go to next/previous card":
        "Click left/right arrows to go to next/previous card",
    "Click on the link to activate your account. Don’t see it? Check your spam folder too :)":
        "Click on the link to activate your account. Don’t see it? Check your spam folder too :)",
    "Click on the answer to the left, and then a match option to the right, in order to make a connection.":
        "Click on the answer to the left, and then a match option to the right, in order to make a connection.",
    "Click on the card to see the back": "Click on the card to see the back",
    "Click on the card to see the front": "Click on the card to see the front",
    "Click the add learning element button in the menu on the left":
        "Click the add learning activity button in the menu on the left",
    "Click the create button to get started":
        "Click the create button to get started",
    "Click the big button in the lower right corner to get started":
        "Click the big button in the lower right corner to get started",
    "Click the button above to begin": "Click the button above to begin",
    "Click the button to add the first learning element":
        "Click the button to add the first learning activity",
    "Click the create button on the left side to get started":
        "Click the create button on the left side to get started",
    "Click this button and click one of the sub-buttons to create a new folder or upload a new file":
        "Click this button and click one of the sub-buttons to create a new folder or upload a new file",
    "Click this button to open the menu with your groups on all pages":
        "Click this button to open the menu with your classes on all pages",
    "Click to start edit": "Click to start edit",
    "Click up/down arrows to flip card": "Click up/down arrows to flip card",
    Close: "Close",
    "Close modal and create": "Close modal and create",
    cloze: "Fill in the blank (Cloze)",
    code: "code",
    Collapse: "Collapse",
    Color: "Color",
    "Color can't be empty.": "Color can't be empty.",
    Comment: "Comment",
    "Comment created": "Comment created",
    "Comment on news": "Comment on news",
    "Comment removed": "Comment removed",
    Comments: "Comments",
    "Compare plans": "Compare plans",
    Completed: "Completed",
    "Completed consultations": "Completed consultations",
    "Completion advisor": "Completion advisor",
    conference: "Conference",
    "Conference thread": "Conference thread",
    Confirm: "Confirm",
    consultations: "Consultations",
    Consultations: "Consultations",
    "Contact support": "Contact support",
    "Contact support@edaptio.com for more info":
        "Contact support@edaptio.com for more info",
    "Contact us": "Contact us",
    Content: "Content",
    Continue: "Continue",
    "Continue where you left off": "Continue where you left off",
    "Conversation members": "Conversation members",
    Conversations: "Conversations",
    "Conversation creation": "Conversation creation",
    "Conversation name": "Conversation name",
    "Collapse all": "Collapse all",
    Collection: "Collection",
    Collections: "Collections",
    Company: "Company",
    "Complete registration": "Complete registration",
    Copied: "Copied",
    "Copied to clipboard": "Copied to clipboard",
    Copy: "Copy",
    "Copy/paste your text source here. Olly will use this as context for generating content":
        "Copy/paste your text source here. Olly will use this as context for generating content",
    "Copy activity": "Copy learning activity",
    "Copy answer to the feedback field": "Copy answer to the feedback field",
    "Copy class": "Copy class",
    "Copy course": "Copy course",
    "Copy course and bypass sync": "Copy course and bypass sync",
    "Copy course and sync": "Copy course and sync",
    "Copy element": "Copy activity",
    "Copy element to these groups?": "Copy activity to these classes?",
    "Copy element to this group?": "Copy activity to this class?",
    "Copy file": "Copy file",
    "Copy file url to clipboard": "Copy file url to clipboard",
    "Copy group": "Copy class",
    "Copy groups": "Copy classes",
    "Copy element and bypass sync": "Copy activity and bypass sync",
    "Copy element and sync": "Copy activity and sync",
    "Copy element to other group": "Copy activity to other class",
    "Copy existing question": "Copy existing question",
    "Copy here": "Copy here",
    "Copy lesson": "Copy lesson",
    "Copy lesson and bypass sync": "Copy lesson and bypass sync",
    "Copy lesson and sync": "Copy lesson and sync",
    "Copy link": "Copy link",
    "Copy new adaptive test": "Copy new adaptive test",
    "Copy of": "Copy of",
    "Copy submission": "Copy submission",
    "Copy to clipboard": "Copy to clipboard",
    "Copy to other groups": "Copy to other classes",
    Corrected: "Corrected",
    Correct: "Correct",
    "Correct answer": "Correct answer",
    "Correct answer(s)": "Correct answer(s)",
    "Correct assignment": "Correct assignment",
    "Correct using DirectEdit": "Correct using DirectEdit",
    "Corresponding answer": "Corresponding answer",
    Counsellor: "Counsellor",
    Counsellors: "Counsellors",
    "Counsellors list": "Counsellors list",
    course: "course",
    Course: "Course",
    "Courses are the top building block of content":
        "Courses are the top building block of your content",
    "Course copied": "Course copied",
    "Course created": "Course created",
    "Course moved": "Course moved",
    "Course name": "Course name",
    "Course progression": "Course progression",
    "Course removed": "Course removed",
    "Course statistics": "Course statistics",
    "Course title": "Course title",
    courses: "Courses",
    Courses: "Courses",
    "Courses are comparable to chapters in a book, and contain several lessons to provide at logical structure for the class content. Make sure to create a course for all of your major subjects of the class.":
        "Courses are comparable to chapters in a book, and contain lessons to group the learning content. Provide at logical structure for the class content! Make sure to create a course for each of your major subjects in the class.",
    Create: "Create",
    "Create account using": "Create account using",
    "Create another": "Create another",
    "Create a class": "Create a class",
    "Create a course": "Create a course",
    "Create a lesson": "Create a lesson",
    "Create a lesson by inserting its name. You can hide this lesson so students will not see it.":
        "Create a lesson by writing a title. You can hide this lesson so students will not see it.",
    "Create a new discussion and get opinions from other members":
        "Create a new debate and get opinions from other members",
    "Create an account": "Create an account",
    "Create assignment": "Create submission",
    "Create assignments in bulk": "Create assignments in bulk",
    "Create and release feedback": "Create and release feedback",
    "Create and show": "Create and show",
    "Create board item": "Create pinboard item",
    "Create class": "Create class",
    "Create collection": "Create collection",
    "Create consultation": "Create consultation",
    "Create course": "Create course",
    "Create discussion": "Create debate",
    "Create feedback": "Create feedback",
    "Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the 'matches with' field to group together fields, that can be placed in the same places in the picture.":
        "Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the 'matches with' field to group together fields, that can be placed in the same places in the picture.",
    "Create flashcards": "Create flashcards",
    "Create folder": "Create folder",
    "Create geogebra": "Create Geogebra activity",
    "Create Geogebra element": "Create Geogebra activity",
    "Create group": "Create class",
    "Create image label": "Create image label",
    "Create lesson": "Create lesson",
    "Create link": "Create link activity",
    "Create media": "Create media",
    "Create new activity": "Create new activity",
    "Create new conversation": "Create new conversation",
    "Create new course": "Create new course",
    "Create new discussion": "Create new debate",
    "Create new group": "Create new class",
    "Create new material": "Create new lesson",
    "Create new question": "Create new question",
    "Create news post": "Create news post",
    "Create now": "Create now",
    "Create conference post": "Create conference post",
    "Create question": "Create question",
    "Create questionnaire": "Create questionnaire",
    "Create quiz": "Create quiz",
    "Create quiz question": "Create quiz question",
    "Create question and show": "Create question and show",
    "Create quiz and show": "Create quiz and show",
    "Create permission group": "Create permission group",
    "Create pinboard": "Create pinboard",
    "Create post": "Create post",
    "Create sub group": "Create sub group",
    "Create subject": "Create subject",
    "Create submission": "Create submission",
    "Create text": "Create text",
    "Create text label": "Create text label",
    "Create timeline": "Create timeline",
    "Create topic": "Create topic",
    "Create user": "Create user",
    "Create widget": "Create widget",
    "Create your account": "Create your account",
    "Create your first class": "Create your first class",
    "Create your first class and invite students":
        "Create your first class and invite learners",
    "Create your first message": "Create your first message",
    Created: "Created",
    "Created learning activities": "Created learning activities",
    "created the assignment": "created the assignment",
    Croatian: "Croatian",
    "Current plan": "Current plan",
    "Currently unavailable": "Currently unavailable",
    "Custom DPA": "Custom DPA",
    "Custom pricing": "Custom pricing",
    "Custom integrations": "Custom integrations",
    CVC: "CVC",
    Czech: "Czech",
    Danish: "Danish",
    Dansk: "Dansk",
    "Dark blue": "Dark blue",
    dashboard: "Dashboard",
    Dashboard: "Dashboard",
    "dashboard-new-teacher-greeting":
        "You do not have any active classes on edaptio yet. <br/> Click below to create your first class.",
    "Data display": "Data display",
    Date: "Date",
    "Date saved": "Date saved",
    "Dedicated support": "Dedicated support",
    "Delete group information": "Delete class information",
    Delete: "Delete",
    "Delete match": "Delete match",
    "Delete match set": "Delete match set",
    "Delete pinboard item?": "Delete pinboard item?",
    "Delete submission": "Delete submission",
    "Default language": "Default language",
    Definition: "Definition",
    "Depending on course(s)": "Depending on course(s)",
    "Depending on following courses": "Depending on following courses",
    Description: "Description",
    "Description too long": "Description too long",
    "Descriptive text": "Descriptive text",
    Design: "Design",
    Deutsch: "German",
    Dialog: "Dialog",
    Difficulty: "Difficulty",
    "Direct edit": "Direct edit",
    "Disable assignment links in assignments app":
        "Disable assignment links in assignments app",
    "Disable video": "Disable video",
    "Disable audio": "Disable audio",
    Discard: "Discard",
    discussions: "Debates",
    Discussions: "Debates",
    "Display news from a specific class by selecting one below":
        "Display news from a specific class by selecting one below",
    "Dive deeper by choosing course and lesson.":
        "Dive deeper by choosing course and lesson.",
    Document: "Document",
    Documents: "Documents",
    Done: "Done",
    Download: "Download",
    "Download as csv": "Download as csv",
    "Download attachment": "Download attachment",
    "Download original file": "Download original file",
    "Download zip": "Download zip file",
    "Do not create sub group": "Do not create sub group",
    "Do not join": "Do not join",
    "Do not have an account?": "Do not have an account?",
    "Do not use deadline": "Do not use deadline",
    "Do you have any other tasks for your friend Olly, the virtual teaching assistant?":
        "Do you have any other tasks for your friend Olly, the virtual teaching assistant?",
    "Do you know the answer?": "Do you know the answer?",
    "Do you need help?": "Do you need help?",
    "Do you really want to leave? you have unsaved changes!":
        "Do you really want to leave? you have unsaved changes!",
    "Do you want to abandon the process?":
        "Do you want to abandon the process?",
    "Do you want to continue, but only copy new elements?":
        "Do you want to continue, but only copy new elements?",
    "Do you want to delete this answer?": "Do you want to delete this answer?",
    "Do you want to delete this consultation?":
        "Do you want to delete this consultation?",
    "Do you want to delete this message for all members?":
        "Do you want to delete this message for all members?",
    "Do you want to enable camera after joining call?":
        "Do you want to enable camera after joining call?",
    "Do you want to end this call for all participants or just leave this conversation?":
        "Do you want to end this call for all participants or just leave this conversation?",
    "Do you want to evenly distribute the rest of the users assignments afterwards?":
        "Do you want to evenly distribute the rest of the learners assignments afterwards?",
    "Do you want to remove": "Do you want to remove",
    "Do you want to remove member from this call?":
        "Do you want to remove {insertion} from this call?",
    "Do you want to you want to leave current call?":
        "Do you want to you want to leave current call?",
    "Do you want to you want to leave current webinar?":
        "Do you want to you want to leave current webinar?",
    "Do you want to synchronize elements that have already been copied?":
        "Do you want to synchronize elements that have already been copied?",
    "Do you want to test out some of our math materials?":
        "Do you want to test out some of our math materials?",
    "Do you wish to join the institution":
        "Do you wish to join the institution",
    "Do you wish to share this with everyone?":
        "Do you wish to share this with everyone?",
    Downgrade: "Downgrade",
    "Drag and drop answers to the dashed boxes with the categories":
        "Drag and drop answers to the dashed boxes with the categories",
    "Drag and drop labels into this box to create alternative (wrong) labels":
        "Drag and drop labels into this box to create alternative (wrong) labels",
    "Drag and drop on image": "Drag and drop on image",
    "Drag and drop options to the dashed boxes with the answers":
        "Drag'n'drop options to the dashed boxes with the answers",
    "Drag and drop to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students":
        "Drag and drop to edit the sequence of the apps and activate/deactivate the app using the lever. You can also hide apps from the learners",
    Drama: "Drama",
    "Drop new learning element here": "Drop new learning activity here",
    "Dropout members": "Dropout learners",
    "Dropped out": "Dropped out",
    Due_date: "Due date",
    "Due date": "Due date",
    "Due date required": "Due date required",
    Duplicate: "Duplicate",
    "Duplicate assignment": "Duplicate assignment",
    "Duplicate flashcard": "Duplicate flashcard",
    "Duplicate learning activity": "Duplicate learning activity",
    "Duplicate question": "Duplicate question",
    Dutch: "Dutch",
    Economics: "Economics",
    "Edaptio group": "Edaptio class",
    "Edaptio primary": "Edaptio primary",
    "Edaptio secondary": "Edaptio secondary",
    Edit: "Edit",
    "Edit assignment": "Edit assignment",
    "Edit audio": "Edit audio",
    "Edit payment method": "Edit payment method",
    "Edit pinboard": "Edit pinboard",
    "Edit board item": "Edit pinboard item",
    "Edit collection": "Edit collection",
    "Edit comment": "Edit comment",
    "Edit consultation": "Edit consultation",
    "Edit course": "Edit course",
    "Edit courses order": "Edit courses order",
    "Edit discussion": "Edit debate",
    "Edit external link": "Edit external link",
    "Edit feedback": "Edit feedback",
    "Edit file": "Edit file",
    "Edit flashcard": "Edit flashcard",
    "Edit flashcards": "Edit flashcards",
    "Edit Geogebra element": "Edit Geogebra element",
    "edit-group": "Edit class",
    "Edit group": "Edit class",
    "Edit group information": "Edit class information",
    "Edit group name": "Edit class name",
    "Edit holiday": "Edit holiday",
    "Edit image": "Edit image",
    "Edit image label": "Edit image label",
    "Edit learning element": "Edit learning activity",
    "Edit material": "Edit lesson",
    "Edit materials order": "Edit lesson order",
    "Edit message": "Edit message",
    "Edit news post": "Edit news post",
    "Edit notebook": "Edit notebook",
    "Edit permission group": "Edit permission group",
    "Edit post": "Edit post",
    "Edit question": "Edit question",
    "Edit questionnaire": "Edit questionnaire",
    "Edit questions": "Edit questions",
    "Edit quiz": "Edit quiz",
    "Edit one learning element at a time. Save changes in order to continue editing.":
        "Edit one learning activity at a time. Save changes in order to continue editing.",
    "Edit timeline": "Edit timeline",
    "Edit selected learning element": "Edit selected learning activity",
    "Edit selected user": "Edit selected user",
    "Edit subject": "Edit subject",
    "Edit tests": "Edit tests",
    "Edit text": "Edit text",
    "Edit text label": "Edit text label",
    "Edit topic": "Edit topic",
    "Edit user": "Edit user",
    Education: "Education",
    Element: "Element",
    "Element already in lesson": "Element already in lesson",
    "Element moved": "Element moved",
    Elements: "Elements",
    "Edit video": "Edit video",
    "Edit vimeo": "Edit vimeo",
    "Edit youtube": "Edit youtube",
    Email: "Email",
    email: "email",
    "Email, chat & onboarding support": "Email, chat & onboarding support",
    "Email & chat support": "Email & chat support",
    "Email / Username": "Email / Username",
    "Email already exists": "Email already exists",
    "Email support": "Email support",
    Embedded: "Embedded",
    "Enable camera": "Enable camera",
    "Enable video": "Enable video",
    "Enable audio": "Enable audio",
    "End chat": "End chat",
    "End for all": "End for all",
    English: "English",
    "Enter class code": "Enter class code",
    "Enter colleague email": "Enter colleague email",
    "Enter your email address and we will send you a new temporary password":
        "Enter your email address and we will send you a new temporary password",
    "Enter your nickname to join this call":
        "Enter your nickname to join this call",
    "Enter your new payment details": "Enter your new payment details",
    "Enter your payment details": "Enter your payment details",
    Error: "Error",
    "Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.":
        "Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.",
    "Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.":
        "Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.",
    "Error occurred while try sign up": "Error occurred while try sign up",
    "Error: time must be in HH:MM:SS format":
        "Error: time must be in HH:MM:SS format",
    Esperanto: "Esperanto",
    "Evaluate all words": "Evaluate all words",
    "Even more storage": "Even more storage",
    Event: "Event",
    Events: "Events",
    Exam: "Exam",
    "exam mode": "exam mode",
    Exams: "Exams",
    "Exam mode": "Exam mode",
    "Exam mode limits the students communication features for an entire class during an exam. Can be turned on/off at any time, and works right away.":
        "Exam mode limits the learners communication features for an entire class during an exam. Can be turned on/off at any time, and works right away.",
    "Exit fullscreen": "Exit fullscreen",
    Expand: "Expand",
    "Expand all": "Expand all",
    "Expiration date of your ongoing subscription plan":
        "Expiration date of your ongoing subscription plan",
    "Expiry date": "Expiry date",
    Expressions: "Expressions",
    "External link": "External link",
    "External service forgotten error":
        "You cannot request a new password on this institution, as you use an external single sign-on provider. Contact the administration on your institution.",
    "External service not linked": "External service not linked to any user",
    "External user id": "External user id",
    "Extra items (uncategorised)": "Extra items (uncategorised)",
    "Failed to activate video chat. Please contact us.":
        "Failed to activate video chat. Please contact us.",
    "Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join":
        "Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join",
    "Failed to lock\\unlock board": "Failed to lock\\unlock pinboard",
    "Failed to lock\\unlock board item": "Failed to lock\\unlock pinboard item",
    "Failed to remove board item": "Failed to remove pinboard item",
    "Failed to save board item": "Failed to save pinboard item",
    "Failed to save information": "Failed to save information",
    "Failed to start video chat. Please contact us.":
        "Failed to start video chat. Please contact us.",
    "Failed to subscribe to board updates. Try to refresh board":
        "Failed to subscribe to pinboard updates. Try to refresh pinboard",
    "Failed to subscribe to conference updates. Try to refresh conference":
        "Failed to subscribe to conference updates. Try to refresh conference",
    "Failed to subscribe to news updates. Try to refresh news":
        "Failed to subscribe to news updates. Try to refresh news",
    "Failed to subscribe to page updates. Try to refresh the page":
        "Failed to subscribe to page updates. Try to refresh the page",
    "Failed to subscribe to post answers updates. Try to refresh post answers":
        "Failed to subscribe to post answers updates. Try to refresh post answers",
    "Failed to delete information": "Failed to delete information",
    FAQ: "FAQ",
    Feedback: "Feedback",
    "Feedback created": "Feedback created",
    "Feedback for this quiz try": "Feedback for this quiz try",
    "Feedback from past assignments": "Feedback from past assignments",
    "Feedback with this answer": "Feedback with this answer",
    "Feedback for answer": "Feedback for answer",
    "Feedback on this answer": "Feedback on this answer",
    "Feedback released": "Feedback released",
    "Feedback saved": "Feedback saved",
    Female: "Female",
    "Field title": "{name} title",
    Fields: "Fields",
    File: "File",
    Files: "Files",
    "File added": "File added",
    "File removed": "File removed",
    "File saved": "File saved",
    "File path": "File path",
    "File too big, please upgrade your account to increase file size limit. (Trial limit:":
        "File too big, please upgrade your account to increase file size limit. (Trial limit:",
    "Files added": "Files added",
    file_sharing: "File sharing",
    "File sharing": "File sharing",
    "Fill it in to log in": "Fill it in to log in",
    Filter: "Filter",
    "Fill in the blank": "Fill in the blank",
    "Fill in the blank (Cloze)": "Fill in the blank (Cloze)",
    "Find members by email": "Find members by email",
    Finish: "Finish",
    Finnish: "Finnish",
    Firstname: "Firstname",
    "First handin": "First submission",
    "Firstname should not be empty": "Firstname should not be empty",
    "Fixed submission date": "Fixed submission date",
    "Flashcards collection": "Flashcards collection",
    "Flashcards collection creation": "Flashcards collection creation",
    "Flashcards collection edit": "Flashcards collection edit",
    "Flashcards tips":
        "Use {param1} to switch cards and {param2} to flip cards",
    "Flexible submission date": "Flexible submission date",
    Float: "Float",
    Folders: "Folders",
    "Folder added": "Folder added",
    "Folder removed": "Folder removed",
    "Folder saved": "Folder saved",
    "Following invites will be sent via email":
        "Following invites will be sent via email",
    For: "For",
    "Forgot password?": "Forgot password?",
    "Forgotten password": "Forgotten password",
    "for clarification.": "for clarification.",
    "For the best experience, we recommend switching to a computer.":
        "For the best experience, we recommend switching to a computer.",
    Fraction: "Fraction",
    Fractions: "Fractions",
    Flashcard: "Flashcard",
    flashcards: "Flashcards",
    Flashcards: "Flashcards",
    Free: "Free",
    French: "French",
    From: "From",
    "From the date your plan will switch to plan":
        "From the {date} your plan will switch to {plan}",
    "from this call?": "from this call?",
    Front: "Front",
    Full: "Full",
    Fullscreen: "Fullscreen",
    "gave feedback for the assignment": "gave feedback for the assignment",
    Gender: "Gender",
    General: "General",
    "General chat": "General chat",
    "General feedback": "General feedback",
    "General question types": "General question types",
    "General settings": "General settings",
    geogebra: "Geogebra",
    Geogebra: "Geogebra",
    "Geogebra creation": "Geogebra creation",
    "Geogebra edit": "Geogebra edit",
    "Geogebra element": "Geogebra element",
    "Geogebra element removed": "Geogebra element removed",
    "Geogebra id": "Geogebra id",
    "Geogebra link or ID": "Geogebra link or ID",
    Geography: "Geography",
    German: "German",
    "Get help from Olly": "Get help from Olly",
    "Get mail for every debate/news/comment in Edaptio": "Get mail for every debate/news/comment in Edaptio",
    "Get mail for every new message in Edaptio": "Get mail for every new message in Edaptio",
    "Get started": "Get started",
    "Get started using Edaptio for free": "Get started using Edaptio for free",
    "Get started with Edaptio": "Get started with Edaptio",
    "Get this code/URL from your teacher.":
        "Get this code/URL from your teacher.",
    "Get submission as zip": "Get submission as zip",
    "Go back to what you have been doing and get a notification when Olly is done":
        "Go back to what you have been doing and get a notification when Olly is done",
    "Go back to where you came from": "Go back to where you came from",
    "Go to group": "Go to class",
    "Go to guide": "Go to guide",
    "Go to link": "Go to link",
    "Go to the class": "Go to the class",
    "Go to the lesson view to add learning elements!":
        "Go to the lesson view to add learning activities!",
    "Good afternoon": "Good afternoon",
    "Good evening": "Good evening",
    "Good morning": "Good morning",
    Google: "Google",
    "Google linked": "Google linked",
    Grade: "Grade",
    "Grade scale": "Grade scale",
    "grade_scale.danish": "Danish 7-step scale",
    "grade_scale.letters": "Letters A-F",
    "grade_scale.numbers": "Numbers 1-6",
    "grade_scale.romanDTU": "Roman numbers I-IV",
    Graded: "Graded",
    Graphical: "Graphical",
    Gray: "Gray",
    Greek: "Greek",
    Group: "Class",
    "Group with": "Group with",
    group_courses: "Course",
    groups: "Classes",
    "Groups in exam mode": "Classes in exam mode",
    "group-information": "Information",
    "group-statistics": "Class statistics",
    "Group created": "Class created",
    "Group hand-in allowed": "Group hand-in allowed",
    "Group name": "Class name",
    "Group news": "Class news",
    "Group archived": "Class archived",
    "Group unarchived": "Class unarchived",
    "Group saved": "Class saved",
    "Group type": "Holdtype",
    Half: "Half",
    "Hatian Creole": "Hatian Creole",
    "Hand down": "Hand down",
    "Hand up": "Hand up",
    "Handed in": "Handed in",
    "Handed in after deadline": "Handed in after deadline",
    "has a hand up": "has a hand up",
    "has started a video-chat. Click here to attend:":
        "has started a video-chat. Click here to attend:",
    "Have you received a class code from your teacher? Then click the “join now” button and become a part of your first class.":
        "Have you received a class code from your teacher? Then click the “join now” button and become a part of your first class.",
    Help: "Help",
    "Help center": "Help center",
    "Here isn't added courses yet": "Here isn't added courses yet",
    "Here you can copy all your class contents to another class. Remember that elements you have previously copied will only be synchronized, and not copied again.":
        "Here you can copy all your class contents to another class. Remember that elements you have previously copied will only be synchronized, and not copied again.",
    "Here you can put groups in exam mode. Exam mode means that all users in the particular group can not use any communication tools on the platform. E.g messages, news and conference":
        "Here you can put classes in exam mode. Exam mode means that all users in the particular class can not use any communication tools on the platform. E.g messages, news and conference",
    "Hey, I am busy at the moment!": "Hey, I am busy at the moment!",
    "Hey! I am Olly, the virtual teaching assistant!":
        "Hey! I am Olly, the virtual teaching assistant!",
    "Hey, Olly has your content ready for review":
        "Hey {name}, Olly has your content ready for review",
    "Hey! Olly failed to create learning activities from of the uploaded source":
        "Hey! Olly failed to create learning activities from of the uploaded source",
    "Hey Edaptio, I am interested in a tailored offer for you platform. My contact information is:":
        "Hey Edaptio, I am interested in a tailored offer for you platform. My contact information is:",
    "HH:MM:SS": "HH:MM:SS",
    "Hidden lesson": "Hidden lesson",
    "Hide element": "Hide element",
    "Hide menu": "Hide menu",
    "Hide non due date assignments": "Hide assignments without due date",
    "Hide/show side menu": "Hide/show side menu",
    Hidden: "Hidden",
    "Hidden assignment": "Hidden assignment",
    "Hidden course": "Hidden course",
    "Hidden folder": "Hidden folder",
    "Hidden from students": "Hidden from learners",
    "Hidden material": "Hidden lesson",
    "Hidden submission": "Hidden submission",
    Hindi: "Hindi",
    History: "History",
    Home: "Home",
    "How do i log in?": "How do i log in?",
    "How do you want to create your activity?":
        "How do you want to create your {name}?",
    "How to get started?": "How to get started?",
    Hungarian: "Hungarian",
    Icelandic: "Icelandic",
    Identifier: "Identifier",
    "I am using another platform": "I am using another platform",
    "I agree": "I agree",
    "I agree to the Terms and Conditions and default Notifications settings":
        "I agree to the {termsLink} and default {notifLink}",
    "I can help you create content for your lessons. Just follow the steps and put me to work!":
        "I can help you create content for your lessons. Just follow the steps and put me to work!",
    "I don’t find the platform valuable": "I don’t find the platform valuable",
    "I have read and understood this text":
        "I have read and understood this text",
    "If unchecked, the user will be presented with all words form the word list.":
        "If unchecked, the learner will be presented with all words form the word list.",
    "If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.":
        "If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.",
    'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.':
        'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.',
    "If your colleagues receive this link and open it they will able to create a copy of the file placed in their shared folder.":
        "If your colleagues receive this link and open it they will able to create a copy of the file placed in their shared folder.",
    "If you're supposed to have access to this page, try refreshing.":
        "If you're supposed to have access to this page, try refreshing.",
    "I'm sure": "I'm sure",
    Image: "Image",
    image: "Image",
    "Image dimensions": "Image dimensions",
    "Image height": "Image height",
    "Image width": "Image width",
    "image-drag-and-drop": "Drag and drop on image",
    Import: "Import",
    "Import data": "Import data",
    "Import existing question": "Import question",
    "Import users": "Import learners",
    "Import wiris instance": "Import wiris instance",
    "In a test, all feedback is received at the end of the quiz.":
        "In a test, all feedback is received at the end of the quiz.",
    "Includes 10 active learners, €0.80/month for each additional learner":
        "Includes 10 active learners, €0.80/month for each additional learner",
    "Includes 10 active learners, €1.60/month for each additional learner":
        "Includes 10 active learners, €1.60/month for each additional learner",
    "Incorrect file name": "Incorrect file name",
    Indonesian: "Indonesian",
    information: "Information",
    Information: "Information",
    "Information deleted": "Information deleted",
    "Information saved": "Information saved",
    "Information title": "Information title",
    Innovation: "Innovation",
    "Input not email": "Input not email",
    "Input too short": "Input too short",
    "Input valid date and time - or skip the deadline":
        "Input valid date and time - or skip the deadline",
    "Input the class name and click create. You can always add members and co-teachers later under the members tab.":
        'Input the class name and click create. You can always add learners and co-teachers later under the "members" tab.',
    "Insert a code received from your teacher in order to join the class":
        "Insert a code received from your teacher in order to join the class",
    "Insert a text below": "Insert a text below",
    "Insert a word or phrase you want learners to hear.":
        "Insert a word or phrase you want learners to hear.",
    "Insert your personal information and set up the platform’s language. You will be able to edit it later in the profile’s settings.":
        "Insert your personal information and set up the platform’s language. You will be able to edit it later in the profile’s settings.",
    Institution: "Institution",
    "Institution/company name": "Institution/company name",
    "Institution groups": "Institution classes",
    "Institution payments": "Institution payments",
    Institutions: "Institutions",
    "Institution language": "Institution language",
    "Institution settings": "Institution settings",
    Integer: "Integer",
    Interactive: "Interactive",
    interactive_objects: "Geogebra",
    "Interactive exercise": "Interactive exercise",
    "International economy": "International economy",
    "Intranet": "Intranet",
    Invalid: "Invalid",
    "Invalid class code": "Invalid class code",
    "Invalid form": "Invalid form",
    "Invalid: Must be at least 1": "Invalid: Must be at least 1",
    "Invalid: Must be at most": "Invalid: Must be at most",
    "Invalid: Must be between 0 - 100": "Invalid: Must be between 0 - 100",
    Invite: "Invite",
    "Invite colleagues": "Invite colleagues",
    "Invite learners": "Invite learners",
    "Invite members": "Invite members",
    "Invite members to your plan": "Invite members to your plan",
    "Invite more": "Invite more",
    "Invite new learners sharing one of following":
        "Invite new learners sharing one of following",
    "Invite Participants": "Invite Participants",
    "Invite participants via link": "Invite participants via link",
    "Invite plan members via email": "Invite plan members via email",
    "Invited by": "Invited by",
    "Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.":
        "Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.",
    "Invite(s) sent.": "Invite(s) sent.",
    Italian: "Italian",
    Item: "Item",
    "It seems that there are no active elements in this class":
        "It seems that there are no active {insertion} in this class",
    Japanese: "Japanese",
    Join: "Join",
    "Join class": "Join class",
    Joined: "Joined",
    "Join call": "Join call",
    "Join institution": "Join institution",
    "Join your class": "Join your class",
    "Join your first class": "Join your first class",
    "Join with a code": "Join with a code",
    "Join with code not allowed": "Join with code not allowed for this class",
    "Keep training": "Keep training",
    Keyword: "Keyword",
    "Knowledge bank": "Knowledge bank",
    Korean: "Korean",
    label: "label",
    "Label group": "Label group",
    "Labels not on image": "Labels not on image",
    Language: "Language",
    "lang.da": "Danish",
    "lang.en": "English",
    "Language saved": "Language saved",
    "Language selection": "Language selection",
    Lastname: "Lastname",
    "Lastname should not be empty": "Lastname should not be empty",
    "Last answered": "Last answered",
    "Last modified": "Last modified",
    Later: "Later",
    "Latest activity": "Latest activity",
    "Latest answers": "Latest answers",
    "Latest handin": "Latest submission",
    "Latest sync": "Latest sync",
    "Latest try": "Latest try",
    Latin: "Latin",
    Latvian: "Latvian",
    "Learning activities": "Learning activities",
    learning_elements: "Learning activities",
    "Learning activities added": "Learning activities added",
    "Learning activities created by Olly are displayed below. You can keep, edit or discard each of them.":
        "Learning activities created by Olly are displayed below. You can keep, edit or discard each of them.",
    "Learning activities to create": "Learning activities to create",
    "Learning elements": "Learning activities",
    "Learning element created": "Learning activity created",
    "Learning element moved": "Learning activity moved",
    "Learning element removed": "Learning activity removed",
    "Learning element saved": "Learning activity saved",
    "Learn more": "Learn more",
    Leave: "Leave",
    "Leave current video chat": "Leave current video chat",
    "Leave current webinar": "Leave current webinar",
    "Left us": "Left us",
    lesson: "Lesson",
    Lesson: "Lesson",
    "Lesson copied": "Lesson copied",
    "Lesson moved": "Lesson moved",
    "Lesson name": "Lesson name",
    "Lesson title": "Lesson title",
    Lessonbank: "Lessonbank",
    lessons: "Lessons",
    Lessons: "Lessons",
    "let all in": "let all in",
    "Let us know why you are leaving:": "Let us know why you are leaving:",
    "Let’s begin!": "Let’s begin!",
    Level: "Level",
    Levels: "Levels",
    Library: "Library",
    Link: "Link",
    link: "link",
    "Link copied!": "Link copied!",
    "Link course to group": "Link course to class",
    "Link element to group": "Link activity to class",
    "Link file to group": "Link file to class",
    "Link learning element to group": "Link learning activity to class",
    "Link lesson to group": "Link material to class",
    "Link text": "Link text",
    "Link to group": "Link to class",
    "Link to join to video chat": "Link to join to video chat",
    Links: "Links",
    "List of participants": "List of participants",
    "List of selected attachments": "List of selected attachments",
    "Load all comments": "Load all comments",
    "Load earlier": "Load earlier",
    "Load external quiz": "Load external quiz",
    "Load flashcards’ preview": "Load flashcards’ preview",
    "Load more": "Load more",
    "Load pinboard items": "Load pinboard items",
    "Load student submissions": "Load learner submissions",
    Loading: "Loading",
    "Lock board": "Lock pinboard",
    "Lock board item": "Lock pinboard item",
    "Locked course. You have not yet completed the prerequisite course(s) for this course.":
        "Locked course. You have not yet completed the prerequisite course(s) for this course.",
    Login: "Login",
    "Login as": "Login as",
    "Login failed with the following message":
        "Login failed with the following message",
    Logout: "Log out",
    "Log in as": "Log in as",
    "Login with": "Login with",
    "Log in with email": "Log in with email",
    "Log in with Facebook": "Log in with Facebook",
    "Log in with Google": "Log in with Google",
    "Log in with Unilogin": "Log in with Unilogin",
    "Log in with Microsoft": "Log in with Microsoft",
    "Looking for a specific user? Search below.":
        "Looking for a specific user? Search below.",
    "Looking to log in? Click here": "Looking to log in? Click here",
    "Lower border": "Lower border",
    "Made by": "Made by",
    Male: "Male",
    "Manage apps": "Manage apps",
    "Manage board item": "Manage pinboard item",
    "Manage groups": "Manage classes",
    "Manage moodle": "Manage moodle",
    "Manage permissions": "Manage permissions",
    "Manage users": "Manage users",
    Mandatory: "Mandatory",
    "manually.": "manually.",
    "Maple TA": "Maple TA",
    Macedonian: "Macedonian",
    "Mark all as read": "Mark all as read",
    "Mark completed": "Mark completed",
    "Mark as unread": "Mark as unread",
    "Mark student as self-study": "Mark learner as self-study",
    "Mark unread": "Mark unread",
    "Marked as read": "Marked as read",
    match: "Match",
    Match: "Match",
    "Match case insensitive": "Match case insensitive",
    "Match case sensitive": "Match case sensitive",
    "Match options": "Match options",
    "Match set": "Match set",
    "Match sets": "Match sets",
    "Match with arrows": "Match with arrows",
    "Matching cloze tag": "Matching cloze tag",
    "Matching with": "Matching with",
    "match-wiris": "Wiris match",
    material: "Lesson",
    material_objects: "Lesson",
    materials: "Lessons",
    "Material added": "Lesson added",
    "Material created": "Lesson created",
    "Material deleted": "Lesson deleted",
    "Material group": "Material class",
    "Material removed": "Lesson removed",
    MathHero: "MathHero",
    Mathematics: "Mathematics",
    Media: "Media",
    media: "media",
    Member: "Member",
    Members: "Members",
    members: "Members",
    "Members can comment on news": "Learners can comment on news",
    "Members can post news": "Learners can post news",
    "Members on plan": "Members on plan",
    Menu: "Menu",
    "Message marked as unread": "Message marked as unread",
    Messages: "Messages",
    Microphone: "Microphone",
    Microsoft: "Microsoft",
    "Microsoft linked": "Microsoft linked",
    min: "min",
    "Minimum count characters": "Minimum {count} characters",
    "Minimum number of assignments behind":
        "Minimum number of submissions behind",
    "Minimum number of questions": "Minimum number of questions",
    "Miro Mind Maps": "Miro Mind Maps",
    "Missing feedback": "Missing feedback",
    Moderator: "Moderator",
    Moldavian: "Moldavian",
    Montenegrin: "Montenegrin",
    Monthly: "Monthly",
    "Monthly payment": "Monthly payment",
    "Monthly payment per active learner": "Monthly payment per active learner",
    "months-full-array": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    "months-short-array": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ],
    Moodle: "Moodle",
    "Moodle group": "Moodle group",
    More: "More",
    "More active learners": "More active learners",
    "More settings": "More settings",
    "More support options": "More support options",
    "Most apps have a central action button - look for it in the same place as this one":
        "Most apps have a central action button - look for it in the same place as this one",
    Move: "Move",
    "Move activity": "Move learning activity",
    "Move all classes to exam mode": "Move all classes to exam mode",
    "Move here": "Move here",
    "Move lesson": "Move lesson",
    "Move to folder": "Move to folder",
    ms_class_notebook: "Class notebook",
    "Multiple choice": "Multiple choice",
    "multiple-choice": "Multiple choice",
    "multi-choice-wiris": "Wiris multiple choice",
    "Multiple choice questions": "Multiple choice questions",
    "Multiple choice settings": "Multiple choice settings",
    Music: "Music",
    "Must be a valid secure link (https)":
        "Must be a valid secure link (https)",
    "Must be between": "Must be between",
    Mute: "Mute",
    Muted: "Muted",
    "Muted mic": "Muted",
    "mute all": "mute all",
    "My institution is not on the list": "My institution is not on the list",
    Name: "Name",
    "Name saved": "Name saved",
    "Need help?": "Need help?",
    "Need more flexible options? Upgrade your plan today":
        "Need more flexible options? Upgrade your plan today",
    Never: "Never",
    "New assignment": "New assignment",
    "New consultation": "New consultation",
    "New email": "New email",
    "New field": "New field",
    "New flashcard creation": "New flashcard creation",
    "New Geogebra element": "New Geogebra activity",
    "New image": "New image",
    "New individual due date": "New individual due date",
    "New information": "New information",
    "New learning activity has been created":
        "New learning activity has been created",
    "New learning element": "New learning activity",
    "New learning element added": "New learning activity added",
    "New message": "New message",
    "New Post": "New Post",
    "New question": "New question",
    "New quiz": "New quiz",
    "New password": "New password",
    "New pinboard item": "New pinboard item",
    "New relation": "New relation",
    "New word": "New word",
    news: "News",
    News: "News",
    "News post creation": "News post creation",
    "New password has been successfully sent via email":
        "New password has been successfully sent via email",
    Next: "Next",
    "Next assignment": "Next assignment",
    "Next course": "Next course",
    "Next lesson": "Next lesson",
    "Next material": "Next lesson",
    "Next month": "Next month",
    "Next payment scheduled for": "Next payment scheduled for",
    "Next question": "Next question",
    "Next submission": "Next submission",
    "Next submission without feedback": "Next submission without feedback",
    "Next topic": "Next topic",
    "Nice, you created a quiz question!": "Nice, you created a quiz question!",
    No: "No",
    "No admins yet": "No admins yet",
    "No answers": "No answers",
    "No assignments found": "No assignments found",
    "No collections found": "No collections found",
    "No completed consultations": "No completed consultations",
    "No consultations to display": "No consultations to display",
    "No conversations": "No conversations",
    "No counsellors": "No counsellors",
    "No course": "No course",
    "No courses found": "No courses found",
    "No credit card required": "No credit card required",
    "No data to display": "No {name} to display",
    "No description": "No description",
    "No elements in this lesson": "No activities in this lesson",
    "No files": "No files",
    "No files or folders yet": "No files or folders yet",
    "No files to export": "No files to export",
    "No Geogebra elements found": "No Geogebra activities found",
    "No information": "No information",
    "No group information created": "No class information created",
    "No groups": "No classes",
    "No groups found": "No classes found",
    "No learning elements found": "No learning activities found",
    "No materials found": "No lessons found",
    "No members": "No members",
    "No members yet": "No members yet",
    "No messages found": "No messages found",
    "No messages to display": "No messages to display",
    "No messages yet": "No messages yet",
    "No news found for this group": "No news found for this class",
    "No notifications": "No notifications",
    "No, only visible for my institution":
        "No, only visible for my institution",
    "No permissions found": "No permissions found",
    "No posts found": "No posts found",
    "No questions": "No questions",
    "No questions found": "No questions found",
    "No questionnaires found": "No questionnaires found",
    "No quizzes found": "No quizzes found",
    "No participants": "No participants",
    "No people found": "No people found",
    "No people found. Keep typing a full email to invite people.":
        "No people found. Keep typing a full email to invite people.",
    "No permission to access your microphone or\\and camera you (or your companions) may experience some problems.":
        "No permission to access your microphone or\\and camera you (or your companions) may experience some problems.",
    "No results": "No results",
    "No results yet": "No results yet",
    "No subjects": "No subjects",
    "No submission date": "No submission date",
    "No text": "No text",
    "No title": "No title",
    "No topic": "No topic",
    "No topics": "No topics",
    "No students": "No learners",
    "No tries yet": "No tries yet",
    "No upcoming consultations": "No upcoming consultations",
    "No videos found": "No videos found",
    "Non-profit": "Non-profit",
    None: "None",
    "Nope, thanks!": "Nope, thanks!",
    Norwegian: "Norwegian",
    Notifications: "Notifications",
    "Notify me": "Notify me",
    "Not assigned to course": "Not assigned to course",
    "Not enough data": "Not enough data",
    "Not active": "Not active",
    "Not added": "Not added",
    "Not chosen": "Not chosen",
    "Not correct": "Not correct",
    "Not completed": "Not completed",
    "Not graded": "Not graded",
    "Not passed": "Not passed",
    "Not released": "Not released",
    "Not submitted": "Not submitted",
    "Not valid video chat session provided. Please restart video call normally Page reload will not help.":
        "Not valid video chat session provided. Please restart video call normally Page reload will not help.",
    "Note that your new subscription plan will be activated on the date":
        "Note that your new subscription plan will be activated on the {date}" /* Translate with param */,
    "Nothing has been shared with you yet":
        "Nothing has been shared with you yet",
    "Notification settings": "Notification settings",
    Now: "Now",
    "now and share the class activity with your students":
        "now and share the class activity with your students",
    "Number of active learners": "Number of active learners",
    "Number of assignments": "Number of assignments",
    "Number of assignments missing feedback":
        "Number of assignments missing feedback",
    "Number of attempts": "Number of attempts",
    "Number of decimals": "Number of decimals",
    "Number of flashcards": "Number of flashcards",
    "Number of lessons": "Number of lesson",
    "Number of missing": "Number of missing",
    "Number of questions": "Number of questions",
    "Number of votes": "Number of votes",
    "Number of words": "Number of words",
    "Number type": "Number type",
    "Numeric variables": "Numeric variables",
    "Oh no! Something went wrong.": "Oh no! Something went wrong.",
    OK: "OK",
    Okay: "Okay",
    Of: "Of",
    of: "of",
    "Of all questions are answered correctly":
        "Of all questions are answered correctly",
    Off: "Off",
    "Old password": "Old password",
    "Olly has (finally) finished the task":
        "Olly has (finally) finished the task",
    "Olly is working hard to create your content":
        "Olly is working hard to create your content",
    "Olly will use this to create learning activities from.":
        "Olly will use this to create learning activities from.",
    On: "On",
    "Oops something went wrong. Maybe you didn't grant permissions to access your camera or microphone.":
        "Oops something went wrong. Maybe you didn't grant permissions to access your camera or microphone.",
    "Oops something went wrong. Please try again.":
        "Oops something went wrong. Please try again.",
    "Only open answer for now": "Only open answer for now",
    Open: "Open",
    "Open answer": "Open answer",
    "Open external link": "Open external link",
    "Open link": "Open link",
    "Open video chat": "Open video chat",
    "Open turnin": "Open submission",
    "open-answer": "Open answer",
    Option: "Option",
    Optional: "Optional",
    "Optional settings": "Optional settings",
    Options: "Options",
    or: "or",
    "Or continue using your phone with limited functionality":
        "Or continue using your phone or tablet with limited functionality",
    Orange: "Orange",
    "Order saved": "Order saved",
    Organize: "Organize",
    Other: "Other",
    "Other Edaptio products": "Other Edaptio products",
    "Other login options": "Other login options",
    "Other reason": "Other reason",
    Overview: "Overview",
    "Oups! This page does not exist!": "Oups! This page does not exist!",
    "Paid monthly": "Paid monthly",
    "Paid yearly": "Paid yearly",
    Page: "Page",
    Pages: "Pages",
    Participants: "Participants",
    Passed: "Passed",
    "Passed or completed": "Passed or completed",
    Password: "Password",
    "Password saved": "Password saved",
    "Passwords must match": "Passwords must match",
    "Pass percentage": "Pass percentage",
    "Past consultations": "Past consultations",
    "Paste wiris XML": "Paste wiris XML",
    Pay: "Pay",
    "Payment details": "Payment details",
    "per active learner": "per active learner",
    "per active learner per month": "per active learner per month",
    "per month": "per month",
    "Per month paid yearly": "Per month paid yearly",
    Percentage: "Percentage",
    "Percentage or fractions": "Percentage or fractions",
    Permission: "Permission",
    Permissions: "Permissions",
    "Permission group": "Permission group",
    "Permission groups": "Permission groups",
    // "permissions.admin_absence" : "Manage absence for the entire institution",
    // "permissions.admin_calendar" : "Manage schedule for the entire institution",
    "permissions.admin_bank": "Admin in bank",
    "permissions.admin_groups_classes":
        "Mange groups and classes for the institution",
    "permissions.admin_institution_settings":
        "Manage general settings for the institution",
    "permissions.admin_moodle_administration":
        "Administer MOODLE for the institution",
    "permissions.admin_permissions": "Manage permissions for the institution",
    "permissions.admin_recommended_material": "Recommend lessons to learners",
    "permissions.admin_sign_in_as_other": "Sign in as other user",
    "permissions.admin_stats": "Access statistics for the institution",
    // "permissions.auto_admin_in_groups" : "Automatic promotion to author when joining groups",
    "permissions.can_view_teacher_activity": "Can view teachers activity",
    "permissions.change_language": "Change language",
    "permissions.change_profile_picture": "Change profile picture",
    "permissions.edit_module_assignments": "Admin rights to app: Assignments",
    "permissions.edit_module_conferences": "Admin rights to app: Conferences",
    "permissions.edit_module_consultations":
        "Admin rights to app: Consultations",
    "permissions.editor_bank": "Editor in bank",
    "permissions.edit_module_file_sharing": "Admin rights to app: File-sharing",
    // "permissions.edit_module_homework" : "Admin rights to app: Homework",
    "permissions.edit_module_information": "Admin rights to app: Information",
    "permissions.edit_module_quizzes": "Admin rights to app: Quizzes",
    "permissions.edit_module_materials": "Admin rights to app: lessons",
    "permissions.edit_module_interactive": "Admin rights to app: Geogebra",
    "permissions.edit_module_paths": "Admin rights to app: Courses",
    "permissions.edit_module_news": "Admin rights to app: News",
    "permissions.edit_module_videos": "Admin rights to app: Videos",
    "permissions.edit_module_homework_aid": "Admin rights to app: Video aid",
    "permissions.generic_replace_youtube_url": "Generic replace Youtube URLs",
    // "permissions.force_join_groups" : "Force other users to join group upon invitation",
    // "permissions.manage_non_admin_groups" : "Manage all groups without being author",
    // "permissions.message_groups_classes" : "Send messages to entire groups and classes",
    // "permissions.profile_absence" : "See absence for other users",
    "permissions.profile_assignments": "See assignments for other users",
    // "permissions.profile_calendar" : "See calendar for other users",
    // "permissions.profile_contracts" : "See contracts for other users",
    // "permissions.profile_grades" : "See grades for other users",
    "permissions.profile_quizzes": "See quizzes for other users",
    "permissions.profile_paths": "See courses for other users",
    "permissions.profile_stats": "See statistics for other users",
    // "permissions.search_entire_institution" : "Search for all group contents",
    // "permissions.see_own_absence" : "See absence for own user",
    // "permissions.see_own_assignments" : "See assignments for own user",
    // "permissions.see_own_calendar" : "See calendar for own user",
    // "permissions.see_own_contracts" : "See contracts for own user",
    // "permissions.see_own_grades" : "See grades for own user",
    // "permissions.see_own_stats" : "See statistics for own user",
    // "permissions.send_reminders" : "Send reminders to students",
    "permissions.view_bank": "Can view bank items",
    // "permissions.view_module_assignments" : "User rights for app: Assignments",
    // "permissions.view_module_conferences" : "User rights for app: Conferences",
    // "permissions.view_module_file_sharing" : "User rights for app: File-sharing",
    // "permissions.view_module_homework" : "User rights for app: Homework",
    // "permissions.view_module_information" : "User rights for app: Information",
    // "permissions.view_module_quizzes" : "User rights for app: Quizzes",
    // "permissions.view_module_paths" : "User rights for app: Courses",
    // "permissions.view_module_news" : "User rights for app: News",
    // "permissions.view_module_materials" : "User rights for app: lessons",
    // "permissions.view_module_interactive" : "User rights for app: Geogebra",
    // "permissions.view_module_videos" : "User rights for app: Videos",
    // "permissions.view_recommended_material" : "User rights to recommended lesson",
    "permissions.user_edit_own_group": "Admin in own class",
    "Personal groups": "Personal classes",
    "Personal onboarding": "Personal onboarding",
    Philosophy: "Philosophy",
    Physics: "Physics",
    "Pick date": "Pick date",
    "Pick due date": "Pick due date",
    "Pick due time": "Pick due time",
    "Pick from file sharing": "Pick from file sharing",
    "Pick new background image": "Pick new background image",
    "Pick time": "Pick time",
    "Pick the same date if it is just a single holiday":
        "Pick the same date if it is just a single holiday",
    Pin: "Pin",
    "Pin post": "Pin post",
    "Pin side menu": "Pin side menu",
    pinboard: "pinboard",
    "Pinboard creation": "Pinboard creation",
    "Pinboard edit": "Pinboard edit",
    "Pinboard text": "Pinboard text",
    Pinboards: "Pinboards",
    Pink: "Pink",
    Pinned: "Pinned",
    Phone: "Phone",
    Plan: "Plan",
    "Plan will be canceled from the date":
        "Plan will be canceled from the {date}",
    "Please fix all errors and try again":
        "Please fix all errors and try again",
    "Please let me finish the previously assigned task before you assign me a new one.":
        "Please let me finish the previously assigned task before you assign me a new one.",
    "Please select a user": "Please select a user",
    "Please select the language of the text in order to get the most accurate results":
        "Please select the language of the text in order to get the most accurate results",
    "Please solve the errors before adding this question":
        "Please solve the errors before adding this question",
    "Please use any other browser for browsing our platform.":
        "Please use any other browser for browsing our platform.",
    "Verify your email": "Verify your email",
    "Please wait until the call owner lets you in":
        "Please wait until the call owner lets you in",
    Polish: "Polish",
    "Pool size": "Pool size",
    Portuguese: "Portuguese",
    Possibilities: "Options",
    Post: "Post",
    "Post comment": "Comment",
    "Post created": "Post created",
    "Post removed": "Post removed",
    "Post saved": "Post saved",
    Practiced: "Practiced",
    "Practice again": "Practice again",
    "Practice quiz": "Practice quiz",
    "Pre-paid active learners number for this plan":
        "Pre-paid active learners number for this plan",
    Premium: "Premium",
    PremiumMonthlyLearnU: "Premium",
    PremiumYearlyLearnU: "Premium",
    Presentation: "Presentation",
    "Press enter to add new field, and backspace to remove":
        "Press enter to add new field, and backspace to remove",
    "Preview data": "Preview data",
    "Preview past feedback": "Preview past feedback",
    "Preview question": "Preview question",
    Previous: "Previous",
    "Previous month": "Previous month",
    "Previous topic": "Previous topic",
    Proceed: "Proceed",
    Profile: "Profile",
    "Profile information": "Profile information",
    "Profile information change": "Profile information change",
    Progress: "Progress",
    Progression: "Progression",
    "Provide more details": "Provide more details",
    "Provided email and password do not match. Please try again.":
        "Provided email and password do not match. Please try again.",
    Psychology: "Psychology",
    "Pull the rows to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students":
        "Pull the icons to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the learners",
    Purple: "Purple",
    "Put labels in the same group to make them interchangeable.":
        "Put labels in the same group to make them interchangeable.",
    Quarter: "Quarter",
    Question: "Question",
    "Question & answers": "Question & answers",
    "Question and answers": "Question and answers",
    "Question feedback": "Question feedback",
    "Question title": "Question title",
    Questionnaire: "Questionnaire",
    questionnaire: "questionnaire",
    questionnaires: "Questionnaires",
    Questionnaires: "Questionnaires",
    Questions: "Questions",
    "Question bank": "Question bank",
    "Question overview": "Question overview",
    "Question statistics": "Question statistics",
    "Question type": "Question type",
    "Questions from the bank": "Questions from the bank",
    "Questions in order": "Questions in order",
    "Questions must have at least one correct answer and cannot be empty":
        "Questions must have at least one correct answer and cannot be empty",
    Queue: "Queue",
    Quiz: "Quiz",
    quiz: "quiz",
    quizzes: "quizzes",
    quiz_objects: "Quiz",
    "quiz-question": "quiz question",
    quiz_question_objects: "Quiz question",
    "Quiz completion percentage": "Quiz completion percentage",
    "Quiz matrix": "Quiz matrix",
    "Quiz mode": "Quiz mode",
    "Quiz name": "Quiz name",
    "Quiz question creation": "Create quiz question",
    "Quiz question editing": "Edit quiz question",
    "Quiz question": "Quiz question",
    "Quiz questions": "Quiz questions",
    "Quiz statistics": "Quiz statistics",
    "Quiz status": "Quiz status",
    "Quiz title": "Quiz title",
    "Quiz type": "Quiz type",
    Quizzes: "Quizzes",
    "Quizzes status": "Quizzes status",
    "Radio buttons": "Radio buttons",
    "raised a hand": "raised a hand",
    "Random pool size": "Random pool size",
    "Random questions": "Random questions",
    React: "React",
    "Read messages": "Read",
    "Read more": "Read more",
    "Reading time": "Reading time",
    "Recalculate assignment plan for user":
        "Recalculate assignment plan for user",
    "Recalculate assignment plans for group":
        "Recalculate assignment plans for class",
    "Recalculate cloze options": "Recalculate cloze options",
    Reconnect: "Reconnect",
    "Refresh board": "Refresh pinboard",
    "Refresh the page if the changes do not appear":
        "Refresh the page if the changes do not appear",
    Register: "Register",
    "Register handin": "Register submission",
    "Register handin manually": "Register submission manually",
    "Register student submission": "Register student submission",
    Rejected: "Rejected",
    "Reject submission": "Reject assignment",
    Released: "Released",
    "Release feedback": "Release feedback",
    Religion: "Religion",
    "Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.":
        "Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.",
    Remove: "Remove",
    "Remove admin": "Remove admin",
    "Remove answer": "Remove answer",
    "Remove assignment": "Remove assignment",
    "Remove attachment": "Remove attachment",
    "Remove audio": "Remove audio",
    "Remove board": "Remove pinboard",
    "Remove collection": "Remove collection",
    "Remove course": "Remove course",
    "Remove external link": "Remove external link",
    "Remove feedback": "Remove feedback",
    "Remove file": "Remove file",
    "Remove flashcard": "Remove flashcard",
    "Remove flashcards": "Remove flashcards",
    "Remove folder": "Remove folder",
    "Remove from material": "Remove from lesson",
    "Remove geogebra": "Remove geogebra",
    "Remove Geogebra element": "Remove Geogebra activity",
    "Remove group": "Remove class",
    "Remove image": "Remove image",
    "Remove label": "Remove label",
    "Remove learning element": "Remove learning activity",
    "Remove match set": "Remove match set",
    "Remove material": "Remove lesson",
    "Remove member": "Remove member",
    "Remove picture": "Remove picture",
    "Remove post": "Remove post",
    "Remove subject": "Remove subject",
    "Remove text": "Remove text",
    "Remove topic": "Remove topic",
    "Remove question": "Remove question",
    "Remove questionnaire": "Remove questionnaire",
    "Remove quiz": "Remove quiz",
    "Remove timeline": "Remove timeline",
    "Remove user": "Remove user",
    "Remove user from the conversation": "Remove user from the conversation",
    "Remove vacation/holiday": "Remove vacation/holiday",
    "Remove video": "Remove video",
    "Remove vimeo": "Remove vimeo",
    "Remove your Facebook account": "Remove your Facebook account",
    "Remove your Google account": "Remove your Google account",
    "Remove youtube": "Remove youtube",
    "Remove variable": "Remove variable",
    "Remove word": "Remove word",
    Rename: "Rename",
    "Reorder elements": "Reorder activities",
    Repeatable: "Repeatable",
    "Repeatable quiz": "Repeatable quiz",
    "Repeat email": "Repeat email",
    "Repeat new password": "Repeat new password",
    "Repeat quiz": "Repeat quiz",
    "Repeat password": "Repeat password",
    "Replace picture": "Replace picture",
    "replied to": "replied to",
    Replies: "Replies",
    "Reply to": "Reply to",
    "Reset password": "Reset password",
    "Reset completion deadlines": "Reset completion deadlines",
    Required: "Required",
    "Required pass percentage": "Required pass percentage",
    "Requires at least": "Requires at least",
    Result: "Result",
    Results: "Results",
    Retry: "Retry",
    "Retry payment": "Retry payment",
    "Return to dashboard": "Return to dashboard",
    Review: "Review",
    "Review results": "Review results",
    Role: "Role",
    Romanian: "Romanian",
    Russian: "Russian",
    Save: "Save",
    "Save (discount)": "Save",
    "Save and attach to feedback": "Save and attach to feedback",
    "Save and release feedback": "Save and release feedback",
    "Save and show": "Save and show",
    "Save assignment": "Save assignment",
    "Save audio file": "Save audio file",
    "Save changes": "Save changes",
    "Save collection": "Save collection",
    "Save external link": "Save external link",
    "Save file": "Save file",
    "Save feedback": "Save feedback",
    "Save flashcards collection": "Save flashcards collection",
    "Save geogebra": "Save geogebra",
    "Save image": "Save image",
    "Save in filesystem": "Save in filesystem",
    "Save order": "Save order",
    "Save question and show": "Save question and show",
    "Save question": "Save question",
    "Save questionnaire": "Save questionnaire",
    "Save quiz": "Save quiz",
    "Save quiz and show": "Save quiz and show",
    "Save video": "Save video",
    "Save Vimeo video": "Save Vimeo video",
    "Save payment details": "Save payment details",
    "Save subject": "Save subject",
    "Save submission": "Save submission",
    "Save text": "Save text",
    "Save timeline": "Save timeline",
    "Save to": "Save to",
    "Save to root folder": "Save to root folder",
    "Save topic": "Save topic",
    "Save widget": "Save widget",
    "Save your quiz": "Save your quiz",
    "Save youtube video": "Save youtube video",
    Saved: "Saved",
    Science: "Science",
    School: "School",
    Search: "Search",
    "Search all students": "Search all learners",
    "Search counsellor": "Search counsellor",
    "Search for users, groups..": "Search for users, classes..",
    "Search group": "Search class",
    "Search members": "Search members",
    "Search person": "Search person",
    "Search question": "Search question",
    "Search results": "Search results",
    "Search student": "Search learner",
    "Search students in the class": "Search learner in the class",
    "Search user": "Search user",
    "Search youtube": "Search Youtube",
    sec: "sec",
    "Security warning": "Security warning",
    "See all": "See all",
    "See all news": "See all news",
    "See past feedback": "See past feedback",
    "See members on plan": "See members on plan",
    "See your feedback below": "See your feedback below",
    Seen: "Seen",
    "Seen by all": "Seen by all",
    "Seen by student": "Seen by learner",
    "Select a folder": "Select a folder",
    "Select a media type that you want to create":
        "Select a media type that you want to create",
    "Select a month": "Select a month",
    "Select a question type": "Select a question type",
    "Select a widget type you want to add":
        "Select a widget type you want to add",
    "Select a year": "Select a year",
    "Select activity type": "Select activity type",
    "Select attachments": "Select attachments",
    "Select file type": "Select file type",
    "Select from list": "Select from list",
    "Select plan": "Select plan",
    "Select submission type": "Select submission type",
    "Select subscription": "Select subscription",
    "Select tags": "Select tags",
    "Select the language of the text source":
        "Select the language of the text source",
    "Select the learning activities you want to create from the text":
        "Select the learning activities you want to create from the text",
    "Selected class": "Selected class",
    "Selected counsellor's students": "Selected counsellor's learners",
    "Selected groups": "Selected classes",
    "Selected members": "Selected members",
    Send: "Send",
    "Send a message to start the chat": "Send a message to start the chat",
    "Send copy to colleagues": "Send copy to colleagues",
    "Send element to these colleagues?": "Send activity to these colleagues?",
    "Send element to this colleague?": "Send activity to this colleague?",
    "Send Invites": "Send Invites",
    "Send message": "Send message",
    "Send new password": "Send new password",
    "Send unreleased feedback": "Send unreleased feedback",
    Sentence: "Sentence",
    Serbian: "Serbian",
    "Serbo-Croatian": "Serbo-Croatian",
    "Set or change an individual due date":
        "Set or change an individual due date",
    Setting: "Setting",
    Settings: "Settings",
    "Settings saved": "Settings saved",
    Setup: "Setup",
    "Sign up": "Sign up",
    "Sign up as user type": "Sign up as {userType}",
    "Sign up with email": "Sign up with email",
    "Sign up with Facebook": "Sign up with Facebook",
    "Sign up with Google": "Sign up with Google",
    "Sign up with Unilogin": "Sign up with Unilogin",
    "Sign up with Microsoft": "Sign up with Microsoft",
    "SimpleMath multiple choice": "SimpleMath multiple choice",
    "Simplemath settings": "Simplemath settings",
    "SimpleMath settings": "SimpleMath settings",
    "SimpleMath short answer": "SimpleMath short answer",
    "simple-math-short-answer": "SimpleMath short answer",
    "simple-math-multiple-choice": "SimpleMath multiple choice",
    Size: "Size",
    Share: "Share",
    "Share class": "Share class",
    "Share course": "Share course",
    "Share element": "Share activity",
    "Share file": "Share file",
    "Share folder": "Share folder",
    "Share material": "Share lesson",
    "Share screen": "Share screen",
    "Share the following link with your collegues so they can use already created material":
        "Share the following link with your collegues so they can use already created material",
    "Share with colleagues": "Share with colleagues",
    shared: "shared",
    "shared the assignment": "shared the assignment",
    "shared the course": "shared the course",
    "shared the flashcard": "shared the flashcard",
    "shared the flashcard collection": "shared the flashcard collection",
    "shared the folder": "shared the folder",
    "shared the geogebra": "shared the geogebra",
    "shared the group": "shared the class",
    "shared the lesson": "shared the lesson",
    "shared the link": "shared the link",
    "shared the media": "shared the media",
    "shared the text": "shared the text",
    "shared the question": "shared the question",
    "shared the questionnaire": "shared the questionnaire",
    "shared the quiz": "shared the quiz",
    "shared the timeline": "shared the timeline",
    "Shared with me": "Shared with me",
    "Short answer": "Short answer",
    "Short answer settings": "Short answer settings",
    "short-answer": "Short answer",
    "short-answer-wiris": "Wiris short answer",
    Show: "Show",
    "Show all": "Show all",
    "Show element": "Show activity",
    "Show info": "Show info",
    "Show less": "Show less",
    "Show more": "Show more",
    "Skip first line": "Skip first line",
    "Skip over elements that have already been copied":
        "Skip over elements that have already been copied",
    Slovak: "Slovak",
    Socialstudies: "Socialstudies",
    "Some elements have already been copied to these groups.":
        "Some elements have already been copied to these classes.",
    "Some elements have already been copied to this group.":
        "Some elements have already been copied to this classes.",
    "Some of the questions failed to be copied":
        "Some of the questions failed to be copied",
    "Sorry to see you go!": "Sorry to see you go!",
    "Sorry, we can not connect your account. Please try again later.":
        "Sorry, we can not connect your account. Please try again later.",
    "Sorry, but you do not have permission to access this page":
        "Sorry, but you do not have permission to access this page",
    Source: "Source",
    "Source successfully uploaded": "Text saved and ready",
    Spanish: "Spanish",
    "Spanish Latin American": "Spanish (Latin American)",
    "Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content.":
        "Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content on this level.",
    Spell: "Stav",
    "spell-speech": "Spell word",
    "Spell word": "Spell word",
    Standard: "Standard",
    StandardMonthlyLearnU: "Standard",
    StandardYearlyLearnU: "Standard",
    "Standard DPA": "Standard DPA",
    Start: "Start",
    "Start broadcasting": "Start broadcasting",
    "Start by building your content with courses":
        "Start by building your content with courses",
    "Start by adding learning elements listed on the left by pressing a plus button or dragging and dropping them below.":
        "Start by adding learning activities listed on the left by pressing the plus button or dragging and dropping them below.",
    "Start exam mode": "Start exam mode",
    "Start making great learning content, by verifying your email.":
        "Start making great learning content, by verifying your email.",
    "Start new discussion": "Start new debate",
    "Start quiz": "Start quiz",
    "Start reading": "Start reading",
    "Start video chat": "Start video chat",
    Statistics: "Statistics",
    Status: "Status",
    "STEM questions": "STEM questions",
    "Stop broadcasting": "Stop broadcasting",
    "Stop exam mode": "Stop exam mode",
    "Stop reading": "Stop reading",
    "Stop sharing": "Stop sharing",
    "Structure course content using lessons":
        "Structure course content using lessons",
    Student: "Learner",
    student: "Learner",
    "Student submission registered by teacher":
        "Student submission registered by teacher",
    students: "learners",
    Students: "Learners",
    "Student administration": "Administration",
    "Student handed in offline": "Student handed in offline",
    "Student has not handed in this submission yet.":
        "Student has not handed in this submission yet.",
    "Student hours": "Study hours",
    "Student hours help indicate to your students how much time they should spend on the submission":
        "Study hours help indicate to your learners how much time they should spend on the submission",
    "Student is not part of any class yet":
        "Student is not part of any class yet",
    "Students can add flashcards": "Learners can add flashcards",
    "Students can see results": "Learners can see results",
    "Students list": "Learners list",
    "Student / student average": "Learner / learner average",
    "Student submission will be registered when the feedback is saved": null,
    "Student Testing": "Learner Testing",
    "Student view": "Learner view",
    Subgroups: "Subgroups",
    Subject: "Subject",
    Subjects: "Subjects",
    Submission: "Submission",
    submission: "submission",
    "Submission creation": "Submission creation",
    "Submission example": "Submission example",
    "Submission information": "Submission information",
    "Submission missing feedback": "Submission missing feedback",
    "Submission plan": "Submission plan",
    "Submission title": "Submission title",
    "Submission type": "Submission type",
    Submissions: "Submissions",
    Submit: "Submit",
    "Submit answer & continue": "Submit answer & continue",
    "Submit questionnaire": "Submit questionnaire",
    Submitted: "Submitted",
    Subscription: "Subscription",
    "Subscription type": "Subscription type",
    Success: "Success",
    "Support mail": "Support mail",
    "Sure, lets go!": "Sure, lets go!",
    Surname: "Surname",
    Swahili: "Swahili",
    "Swap out": "Swap out",
    Swedish: "Swedish",
    Switch: "Switch",
    "Switch additional group": "Switch additional class",
    "Switch back to main user": "Switch back to main user",
    "Switch devices now to start adding learning activities into your lessons.":
        "Switch devices now to start adding learning activities into your lessons.",
    "Switch main group": "Switch main class",
    "Switch to add these activities:": "Switch to add these activities",
    Sync: "Sync",
    "Synchronize elements that have already been copied":
        "Synchronize elements that have already been copied",
    "Synchronize hashtags manually": "Synchronize hashtags manually",
    Tags: "Tags",
    Tamil: "Tamil",
    Teacher: "Teacher",
    Teachers: "Teachers",
    "Teacher view": "Teacher view",
    "Teachers on plan": "Teachers on plan",
    Term: "Term",
    "Terms & definitions": "Terms & definitions",
    "Terms and Conditions": "Terms and Conditions",
    Test: "Test",
    "Test answer": "Test answer",
    text: "Text",
    Text: "Text",
    "Text can't be empty and should be shorter then 255 characters.":
        "Text can't be empty and should be shorter then 255 characters.",
    "Text copied": "Text copied",
    "Text on label": "Text on label",
    "Text title": "Text title",
    Time: "Time",
    Timeline: "Timeline",
    timeline: "timeline",
    "Time in HH:MM:SS": "Time in HH:MM:SS",
    "Time to complete course (days)": "Time to complete course (days)",
    Timelines: "Timelines",
    Timestamp: "Timestamp",
    Tip: "Tip",
    Title: "Title",
    "Title required": "Title required",
    "Title too long": "Title too long",
    Thai: "Thai",
    "Thanks for your answer": "Thanks for your answer",
    "Thank you for your interest in Edaptio.":
        "Thank you for your interest in Edaptio.",
    "Thank you for your understanding": "Thank you for your understanding",
    "That was all I got for you this time":
        "That was all I got for you this time",
    "The amount of random questions each learner receives":
        "The amount of random questions each learner receives",
    "The assignment is closed for handin":
        "The assignment is closed for submissions",
    "The call owner denied your request to join":
        "The call owner denied your request to join",
    "The corrections have been attached to the feedback":
        "The corrections have been attached to the feedback",
    "The elements have been added to the existing learning activity":
        "The elements have been added to the existing learning activity",
    "The Geogebra element has been created":
        "The Geogebra activity has been created",
    "The Geogebra element has been saved":
        "The Geogebra activity has been saved",
    "The group is currently in exam mode. All communication suspended.":
        "The class is currently in exam mode. All communication suspended.",
    "The question has been created": "The quiz has been created",
    "The question has been saved": "The quiz has been saved",
    "The quiz has been created": "The quiz has been created",
    "The quiz has been saved": "The quiz has been saved",
    "The message was succesfully deleted":
        "The message was succesfully deleted",
    "The price is too high": "The price is too high",
    "The system cannot auto-correct open answer questions, but your teacher can see your answer.":
        "The system cannot auto-correct open answer questions, but your teacher can see your answer.",
    "The system rejected the request with the following message":
        "The system rejected the request with the following message",
    "The user has received an email with all the necessary information to get started":
        "The user has received an email with all the necessary information to get started",
    "The user will write an open ended text here":
        "The learner will write an open ended text here",
    "There are no answers posted. Add a first answer to this post.":
        "There are no answers yet. Be the first to answer this post.",
    "There are no existing data to display. Start by creating one direct":
        "There are no existing {name} to display. Create one by choosing it in the side menu",
    "There are no items to display": "There are no items to display",
    "There are no learning elements in this category to display. Start by creating one":
        "There are no learning activities in this category to display. Start by creating one",
    "There are no lessons to display. Start by creating one":
        "There are no lessons to display. Start by creating one",
    "There are no consultations created. Start by creating one":
        "You have not created a consultation yet. Start by creating one",
    "There are no submissions to display. Start by creating one":
        "There are no submissions to display. Start by creating one",
    "There are unsaved changes": "There are unsaved changes",
    "There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.":
        "There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.",
    "This can take up to a few minutes, so feel free to grab a cup of coffee or practice your juggling skills while you wait":
        "This can take up to a few minutes, so feel free to grab a cup of coffee or practice your juggling skills while you wait",
    "This course is a reference from another group, and can therefore not be edited here.":
        "This course is a reference from another class, and can therefore not be edited here.",
    "This course is depending on the other courses. Students can not start it until they complete the other.":
        "This course is depending on the other courses. Learners can not start it until they complete the other.",
    "This element has been copied to this group before.":
        "This element has been copied to this class before.",
    "This group does not have a class notebook yet. Contact your teacher in order to fix this.":
        "This class does not have a class notebook yet. Contact your teacher in order to fix this.",
    "this guide": "this guide",
    "This is a beta feature, and errors can occur":
        "This is a beta feature, and errors can occur",
    "This is a collection of all the videos from your courses":
        "This is a collection of all the videos from your courses",
    "This is the number of learners not covered in your pre-paid plan. These will be charged separately according to our pricing plan.":
        "This is the number of learners not covered in your pre-paid plan. These will be charged separately according to our pricing plan.",
    "This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.":
        "This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.",
    "This is an overview of the students progression through the videos in the courses/lessons.":
        "This is an overview of the learners progression through the videos in the courses/lessons.",
    "This lesson is a reference from another group, and can therefore not be edited here.":
        "This lesson is a reference from another class, and can therefore not be edited here.",
    "This lesson is empty.": "This lesson is empty.",
    "This message was deleted": "This message was deleted",
    "This object is empty": "This {name} is empty",
    "This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.":
        "This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.",
    "This widget imports all of your courses automatically":
        "This widget imports all of your courses automatically",
    "This will hold news from your classes":
        "This will hold news from your classes",
    "Three quarter": "Three quarter",
    to: "to",
    To: "To",
    "Toggle fullscreen mode": "Toggle fullscreen mode",
    Today: "Today",
    Top: "Top",
    Topic: "Topic",
    Topics: "Topics",
    "to join your class.": "to join your class.",
    "To top": "To top",
    "Toggle fullscreen": "Toggle fullscreen",
    Total: "Total",
    "Total number of assignment turnins":
        "Total number of assignment submissions",
    Trainable: "Trainable",
    "Train mathematics": "Train mathematics",
    Translate: "Translate",
    "Translate word": "Translate word",
    Tries: "Tries",
    Try: "Try",
    "Try again": "Try again",
    "Try Edaptio": "Try Edaptio",
    "Try now": "Try now",
    "Try to adjust the text": "Try to adjust the text",
    Turkish: "Turkish",
    "Turnin overview": "Submission overview",
    "Turn in quiz": "Turn in quiz",
    "Turn on/off exam mode": "Turn on/off exam mode",
    "Turn video off": "Turn video off",
    "Turn video on": "Turn video on",
    Type: "Type",
    "Type in a definition": "Insert definition",
    "Type in a question": "Write a question",
    "Type in a term": "Insert term",
    "Type in an answer": "Write an answer",
    "Type in your comment": "Write your comment",
    "Type in your message": "Write your message",
    "Type of billing": "Type of billing",
    "Type something": "Type something",
    "UK English": "English (UK)",
    UMS: "UMS",
    "UMS group": "UMS group",
    Unarchive: "Unarchive",
    "Unarchive group": "Unarchive class",
    "Unarchive for all": "Unarchive for all",
    "Unarchive for you": "Unarchive for you",
    Unassign: "Unassign",
    Unilogin: "Unilogin",
    "Unilogin username": "Unilogin username",
    Unknown: "Unknown",
    "Unlimited active learners": "Unlimited active learners",
    "Unlimited classes, courses & lessons":
        "Unlimited classes, courses & lessons",
    "Unlimited co-teachers": "Unlimited co-teachers",
    "Unlimited storage": "Unlimited storage",
    "Unlock board": "Unlock pinboard",
    "Unlock board item": "Unlock pinboard item",
    Unmute: "Unmute",
    Unpin: "Unpin",
    "Unpin post": "Unpin post",
    "Unpin side menu": "Unpin side menu",
    "Unread messages": "Unread",
    Unsubscribe: "Unsubscribe",
    "Up to 100 active learners": "Up to 100 active learners",
    "Up to 250 active learners": "Up to 250 active learners",
    "Upcoming consultations": "Upcoming consultations",
    "Update and log in": "Update and log in",
    "Update profile picture": "Update profile picture",
    "updated the assignment": "updated the assignment",
    Upgrade: "Upgrade",
    "Upgrade plan": "Upgrade plan",
    "Upgrade your plan": "Upgrade your plan",
    "Upgrade your plan to increase the storage space":
        "Upgrade your plan to increase the storage space",
    Upload: "Upload",
    "Upload an example submission that yours students will see after they receive your feedback for the submission":
        "Upload an example submission that yours learners will see after they receive your feedback for the submission",
    Uploads: "Uploads",
    "Upload feedback": "Upload feedback",
    "Upload new file": "Upload new file",
    "Upper border": "Upper border",
    "Ups! This page does not exist!": "Ups! This page does not exist!",
    "Use 0 if you want to disable it":
        "Use 0 if you want to disable the pass percentage",
    "Use in group": "Use in class",
    "Use course in group": "Use course in class",
    "Use hashtags before the words in the text that need to be filled in.":
        "Use hashtags {insertion} before the words in the text that need to be filled in.",
    "Use hashtags to indicate word insertions":
        "Use hashtags to indicate word insertions",
    "Use learning element in group": "Use learning activity in class",
    "Use lessons to divide your courses into smaller bites, that are easier for users to digest. Lessons are packages of learning elements that provide context to the learning the learner has to obtain.":
        "Use lessons to divide your courses into smaller bites, that are easier for learners to digest. Lessons are packages of learning activities that provide context to the learning the learner has to obtain.",
    "Use material in group": "Use lesson in class",
    "Use the arrow keys to switch and flip the cards":
        "Use the arrow keys to switch and flip the cards",
    "Use the feedback, improve your assignment and hand in again":
        "Use the feedback, improve your assignment and hand in again",
    "Use this in a group": "Use this in a class",
    User: "User",
    "User assignments behind": "Learner assignments behind",
    "User assignment progression throughout courses":
        "Learner assignment progression throughout courses",
    "User created": "User created",
    "User creation": "User creation",
    "User is presented with a single random word":
        "Learner is presented with a single random word",
    "User is presented with all words": "Learner is presented with all words",
    "User name": "User name",
    "User progression": "Learner progression",
    "User quiz progression": "Learner quiz progression",
    "User deleted": "User deleted",
    "User saved": "User saved",
    "User was removed from conversation":
        "{user_name} was removed from {conversation_name} conversation",
    Users: "Users",
    "Users not yet started": "Users not yet started",
    "Users under average completion": "Learners under average completion",
    Username: "Username",
    "US English": "English (US)",
    Validating: "Validating",
    Value: "Value",
    "video-conference": "Video conference",
    Video: "Video",
    "Video chat": "Video chat",
    "Video chat does not exist or it's already finished.":
        "Video chat does not exist or it's already finished.",
    "Video conferencing & messaging": "Video conferencing & messaging",
    "Video ID/link": "Video ID/link",
    "Video id required": "Video id required",
    "Video is off": "Video is off",
    "Video is on": "Video is on",
    videos: "Videos",
    "Video preview": "Video preview",
    "Video quiz": "Video quiz",
    Vietnamese: "Vietnamese",
    View: "View",
    "Viewing as a student": "Viewing as a learner",
    "Viewing as a teacher": "Viewing as a teacher",
    "View messages": "View messages",
    Vimeo: "Vimeo",
    "Vimeo video ID/Link": "Vimeo video ID/Link",
    "Visible to students": "Visible to learners",
    "Visual arts": "Billedkunst",
    "Voice gender": "Voice gender",
    webinars: "Webinars",
    weekdays: {
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday"
    },
    "Weekdays-full-array": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ],
    "Weekdays-short-array": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    Welsh: "Welsh",
    "We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.":
        "We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.",
    "We can not find any data to display. Wait for your teacher to add some.":
        "We can not find any {insertion} to display. Wait for your teacher to add some.",
    "We can not find any data to display. Wait for your teacher to create submission.":
        "We can not find any {insertion} to display. Wait for your teacher to create submission.",
    "We can not find any data to display. Wait for your teacher to upload some.":
        "We can not find any {insertion} to display. Wait for your teacher to upload some.",
    "We can not find any lesson in course. Wait for your teacher to add some.":
        "We can not find any lesson in '{insertion}' course. Wait for your teacher to add some.",
    "We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.":
        "We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.",
    "We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.":
        "We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.",
    "We have sent you a temporary password via email":
        "We have sent you a temporary password via email",
    "We sent a verification link to": "We sent a verification link to {email}",
    "Welcome to": "Welcome to",
    "What are you?": "What are you?",
    "What do you want to create?": "What do you want to create?",
    "What educational level?": "What educational level?",
    "What media should the users turn in?":
        "What media should the learners submit?",
    "What type of assignment?": "What type of assignment?",
    "What would you like to learn about?":
        "What would you like to learn about?",
    "When is the assignment due?": "When is the assignment due?",
    "Who are you?": "Who are you?",
    "WIRIS match": "WIRIS match",
    "WIRIS multiple choice": "WIRIS multiple choice",
    "WIRIS short answer": "WIRIS short answer",
    "Write your answer": "Write your answer",
    "Write comment": "Write comment",
    "Write description": "Write description",
    "Write here": "Write here",
    "Write message": "Write message",
    "Write the code from the marked area below in the text field, to confirm deletion":
        "Write the code from the marked area below in the text field, to confirm deletion",
    "Write what you hear": "Write what you hear",
    "Wrong email": "Wrong email",
    "Wrong username/password": "Wrong username/password",
    "Year/date/title": "Year/date/title",
    Yearly: "Yearly",
    "Yearly payment": "Yearly payment",
    "yearly total": "yearly total",
    Yes: "Yes",
    "Yes, archive this group": "Yes, archive this class",
    "Yes, delete this assignment": "Yes, delete this assignment",
    "Yes, delete this collection": "Yes, delete this collection",
    "Yes, delete this feedback": "Yes, delete this feedback",
    "Yes, delete this file": "Yes, delete this file",
    "Yes, delete this folder": "Yes, delete this folder",
    "Yes, delete this Geogebra element": "Yes, delete this Geogebra activity",
    "Yes, delete this group": "Yes, delete this class",
    "Yes, delete this group information": "Yes, delete this class information",
    "Yes, delete this post": "Yes, delete this post",
    "Yes, delete this question": "Yes, delete this question",
    "Yes, delete this quiz": "Yes, delete this quiz",
    "Yes, delete this user": "Yes, delete this user",
    "Yes, everyone can use this": "Yes, everyone can use this",
    "Yes, turn in this questionnaire": "Yes, turn in this questionnaire",
    "Yes, turn in this quiz": "Yes, turn in this quiz",
    "Yes, remove this course": "Yes, remove this course",
    "Yes, remove this learning element": "Yes, remove this learning activity",
    "Yes, remove this member": "Yes, remove this member",
    "Yes, remove this material": "Yes, remove this lesson",
    "Yes, start video call": "Yes, start video call",
    Yesterday: "Yesterday",
    You: "You",
    "You have finished this quiz! You got percent correct.":
        "You have finished this quiz! You got {insertion} correct.",
    "You have run out of storage": "You have run out of storage",
    "Your assignment plan": "Your assignment plan",
    "Your assignment turnin": "Your assignment submission",
    "Your classes": "Your classes",
    "Your class has multiple sub-groups. Choose which one to view for.":
        "Your class has multiple sub-groups. Choose which one to view for.",
    "Your class library is empty, create your first class and start using Learnu":
        "Your class library is empty, create your first class and start using Learnu",
    "Your files have been uploaded and matched.":
        "Your files have been uploaded and matched with feedback.",
    "Your payment details": "Your payment details",
    "Your plan": "Your plan",
    "Your streak": "Your streak",
    "Your subscription payment failed.": "Your subscription payment failed.",
    "Your subscription plan": "Your subscription plan",
    Youtube: "Youtube",
    "Youtube video": "Youtube video",
    "YouTube video ID": "YouTube video ID",
    "You answered correct": "You answered correct",
    "You are currently in exam mode.": "You are currently in {insertion}.",
    "You are currently in exam mode. All communication suspended.":
        "You are currently in exam mode. All communication suspended.",
    "You are in a waiting room": "You are in a waiting room",
    "You are in training mode. Your answers are not recorded, and you get instant feedback. Start the quiz to complete it.":
        "You are in training mode. Your answers are not recorded, and you get instant feedback. Start the quiz to complete it.",
    "You are subscribing to": "You are subscribing to",
    "You are up!": "You are up!",
    "You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.":
        "You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.",
    "You can find the course in your group":
        "You can find the course in your class",
    "You can find the element on the material":
        "You can find the activity on the lesson",
    "You can find the material on the course":
        "You can find the lesson on the course",
    "You can not access your assignments here in this group. This will serve as an overview only.":
        "You can not access your assignments here in this class. This will serve as an overview only.",
    "You can not enter the program this way.":
        "You can not enter the program this way.",
    "You can not have a larger question pool than number of questions attached":
        "You can not have a larger question pool than number of questions attached",
    "You can't join this call": "You can't join this call",
    "You can only add admins here": "You can only add admins here",
    "You can reject the assignment and force the student to hand in again":
        "You can reject the assignment and force the learners to hand in again",
    "You can see this guide to get started":
        "You can see this guide to get started",
    "You did not choose all the correct answers":
        "You did not choose all the correct answers",
    "You do not have much time left for this assignment":
        "You do not have much time left for this assignment",
    "You have a good amount of time left for this assignment":
        "You have a good amount of time left for this assignment",
    "You have already handed this assignment in.":
        "You have already handed this assignment in.",
    "You have answered all the questions in this topic. Keep training or go to the next topic.":
        "You have answered all the questions in this topic. Keep training or go to the next topic.",
    "You have assigned duplicate rows to represent the same value":
        "You have assigned duplicate rows to represent the same value",
    "You have been invited to this class via email":
        "You have been invited to this class via email",
    "You have created a new course": "You have created a new course",
    "You have created a new lesson": "You have created a new lesson",
    "You have missed the due date, or the assignment has been rejected":
        "You have missed the due date, or the assignment has been rejected",
    "You have no messages yet, create your first message to talk to your friends":
        "You have no messages yet, create your first message to talk to your friends",
    "You have not yet completed the course(s) on which this course depends on":
        "You have not yet completed the course(s) on which this course depends on",
    "You have to have atleast one question for your quiz":
        "You have to have atleast one question for your quiz",
    "You must add either a group or a person to the conversation":
        "You must add either a class or a person to the conversation",
    "You must choose minimum one answer": "You must choose minimum one answer",
    "You must choose more than one answer":
        "You must choose more than one answer",
    "You must choose only one answer": "You must choose only one answer",
    "You only need to choose one answer": "You only need to choose one answer",
    "You will automatically be added as admin in the group upon creation":
        "You will automatically be added as admin in the class upon creation",
    "You will need a computer for this part to work smoothly!":
        "You will need a computer for this part to work smoothly!",
    "Your answer": "Your answer",
    "Your answer(s)": "Your answer(s)",
    "Your answer percentage": "Your answer percentage",
    "Your answer was not correct": "Your answer was not correct",
    "Your groups": "Your classes",
    "Your level": "Your level",
    "Your nickname": "Your nickname",
    "Your submission": "Your submission",
    "Your token is not valid or expired. Try again.":
        "Your link is not valid or expired. Try again.",
    "Youtube search": "Youtube search",
    "Want more features and discounts? Request a quote for your entire school or organization today.":
        "Want more features and discounts? Request a quote for your entire school or organization today.",
    "wants to join": "wants to join",
    "wants to join video chat": "wants to join video chat",
    "was archived by": "was archived by",
    "Waiting list": "Waiting list",
    "Waiting for others to arrive": "Waiting for others to arrive",
    "Watch out": "Watch out",
    "We can not find any course in class. Your teacher should add some courses soon.":
        "We can not find any course in {className} class. Your teacher should add some courses soon.",
    "We can not find any *** to display. Wait for your teacher to create ***.":
        "We can not find any {learningElement} to display. Wait for your teacher to create {learningElement}.",
    "We could not find matching information to the typed search phrase":
        "We could not find matching information to the typed search phrase",
    "We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.":
        "We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.",
    "What element would you like want to add?":
        "What activity would you like want to add?",
    "White labeling": "White labeling",
    widget: "widget",
    "Wiris settings": "Wiris settings",
    Word: "Word",
    Words: "Words",
    "Words list": "Words list",
    "Wrong password": "Wrong password"
};
